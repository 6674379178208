<template>
  <div class="contain">
    <div class="circle"
      v-for="(item, index) in buildingInfo"
      :key="index"
      :style="{
        left: item.positionX + '%',
        top: item.positionY + '%'
      }"
      @mouseover="handleMouseenter(index)"
      @mouseleave="handleMouseleave(index)">
      <div class="detail" v-show="item.show">
        <h3 style="margin-bottom: 10px;">楼层信息</h3>
        <div class="detail-item">
          <div class="detail-left">楼层：</div>
          <div class="detail-right">{{item.name}}</div>
        </div>
        <!-- <div class="detail-item">
          <div class="detail-left">总面积：</div>
          <div class="detail-right">{{item.total}}</div>
        </div>
        <div class="detail-item">
          <div class="detail-left">已租：</div>
          <div class="detail-right">{{item.rented}}</div>
        </div>
        <div class="detail-item">
          <div class="detail-left">未租：</div>
          <div class="detail-right">{{item.toRent}}</div>
        </div> -->
        <div style="float:right;cursor:pointer" @click="handleView(item, index)">查看更多</div>
        <!-- <el-table :data="item.companys">
          <el-table-column prop="companyName" label="公司名称"></el-table-column>
        </el-table> -->
      </div>
    </div>
    <div class="head">
      <h2>{{user.orgName}}</h2>
      <div class="time">{{ time }}</div>
    </div>
    <div class="mainbox" v-if="isDefault">
      <div class="nav">
        <div class="box" style="height: 220px">
          <div class="tit">
            <template v-if="user.orgName == '青海华汇新能源有限公司'"><span>固定资产情况</span></template>
            <template v-else><span>资产出租情况（m<sup>2</sup>）</span></template>
            <p></p>
          </div>
          <div class="boxnav">
            <div class="box-item box-item2">
              <div class="icon">
                <img src="@/assets/images/screen/icon5.png" />
              </div>
              <div>
                <template v-if="user.orgName == '青海华汇新能源有限公司'"><span class="little-title">生产设备</span></template>
                <template v-else><span class="little-title">公共配套面积</span></template>
                <p><em>{{generalInfo.publicArea}}</em></p>
              </div> 
            </div>
            <div class="box-item box-item2">
              <div class="icon">
                <img src="@/assets/images/screen/icon1.png" />
              </div>
              <div>
                <template v-if="user.orgName == '青海华汇新能源有限公司'"><span class="little-title">办公厂房</span></template>
                <template v-else><span class="little-title">可租资产面积</span></template>
                <p><em>{{generalInfo.total}}</em></p>
              </div> 
            </div>
            <div class="box-item box-item2">
              <div class="icon">
                <img src="@/assets/images/screen/icon2.png" />
              </div>
              <div>
                <span class="little-title">闲置资产面积</span>
                <!-- <p><em>{{generalInfo.rented}}</em></p> -->
                <p><em>{{generalInfo.toRent}}</em></p>
              </div>
            </div>
            <div class="box-item box-item2">
              <div class="icon">
                <img src="@/assets/images/screen/icon3.png" />
              </div>
              <div>
                <span class="little-title">已租资产面积</span>
                <!-- <p><em>{{generalInfo.toRent}}</em></p> -->
                <p><em>{{generalInfo.rented}}</em></p>
              </div>
            </div>
            <div class="box-item2" style="display:flex">
              <div class="icon"><img src="@/assets/images/screen/icon4.png" /></div>
              <div>
                <span class="little-title">即将到期资产面积</span>
                <p><em>{{generalInfo.expired}}</em></p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="box box1">
          <div class="tit">
            <span>租金收入情况</span>
            <p></p>
          </div>
          <div id="echart1" style="height: 200px"></div>
        </div> -->
        <div class="box box1">
          <div class="tit">
            <template v-if="user.orgName == '青海华汇新能源有限公司'"><span>应收账款情况</span></template>
            <template v-else><span>租赁合同收益情况</span></template>
            <p></p>
          </div>
          <div id="echart2" style="height: 200px"></div>
        </div>
        <div class="box box1">
          <div class="tit">
            <template v-if="user.orgName == '青海华汇新能源有限公司'"><span>应付账款情况</span></template>
            <template v-else><span>物业费用收益情况</span></template>
            <p></p>
          </div>
          <div id="echart6" style="height: 200px"></div>
        </div>
        <div class="box box1">
          <div class="tit">
            <span>企业考核情况</span>
            <p></p>
          </div>
          <div id="echart4" style="height: 200px"></div>
        </div>
      </div>
      <div class="nav">
        <div class="box box1">
          <div class="tit">
            <template v-if="user.orgName == '青海华汇新能源有限公司'"><span>客户种类</span></template>
            <template v-else><span>企业培育情况</span></template>
            <p></p>
          </div>
          <div id="echart5" style="height: 200px"></div>
        </div>
        <div class="box box3">
          <div class="tit">
            <span>企业种类对比</span>
            <p></p>
          </div>
          <div id="echart3" :style="{height: `${height + 220}px`}"></div>
        </div>
        <div class="box box2">
          <div class="tit">
            <template v-if="user.orgName == '青海华汇新能源有限公司'"><span>新增订单提醒</span></template>
            <template v-else><span>新增租赁提醒</span></template>
            <p></p>
          </div>
          <div style="overflow: hidden">
            <div class="box-item1" v-for="(item, index) in newRent.slice(0, expireRent.length ? 3 : 6)" :key="index">
              <div class="box-item-left">已签订</div>
              <div class="box-item-right">
                <div class="box-item-right-item">
                  <div class="box-item-right-item-left">
                    {{item.companyName}}
                  </div>
                  <div class="box-item-right-item-right">{{item.legalPerson}}</div>
                </div>
                <div class="box-item-right-item">
                  <div>{{item.info}}
                    <!-- <span style="margin-left: 10px"
                      >{{item.time}}</span
                    > -->
                  </div>
                  <div class="box-item-right-item-right">{{item.time}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box box2" v-if="expireRent.length">
          <div class="tit">
            <span>到期租赁提醒</span>
            <p></p>
          </div>
          <div style="overflow: hidden">
            <div class="box-item1" v-for="(item, index) in expireRent.slice(0, newRent.length ? 3 : 6)" :key="index">
              <div class="box-item-left" style="background: #fac858">提醒</div>
              <div class="box-item-right">
                <div class="box-item-right-item">
                  <div class="box-item-right-item-left">
                    {{item.companyName}}
                  </div>
                  <!-- <div class="box-item-right-item-right">{{item.companyType}}</div> -->
                </div>
                <div class="box-item-right-item">
                  <div>
                    {{item.info}}
                    <span style="margin-left: 10px">{{item.time}}</span>
                  </div>
                  <div class="box-item-right-item-right">{{item.legalPerson}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mainbox" v-else>
      <div v-for="(item, index) in cfDboardContList" :key="index" style="width: 50%" class="boxb">
        <div style="min-width: 330px;width: 55%;min-height: 300px;height: 30vh
        border: 1px solid #242f67;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        margin-bottom: 20px;">
          <div class="tit">
            <span>{{ item.name }}</span>
            <p></p>
          </div>
          <!-- 柱状 -->
          <div v-if="item.type == '1'">
            <div :id="'echart' + index" class="echart" style="height: 250px"></div>
          </div>
          <!-- 折线 -->
          <div v-if="item.type == '2'">
            <div :id="'echart' + index" class="echart" style="height: 250px"></div>
          </div>
          <!-- 表格 -->
          <div v-if="item.type == '3'" class="flex">
            <el-table
              :data="item.tableData"
              :header-cell-style="{'background': 'rgba(0, 0, 0, 0.89)', 'color': '#fff', padding: '5px 0'}"
              :row-style="{'background': 'rgba(0, 0, 0, 0.9)','color': '#fff'}"
              default-expand-all
              :row-class-name="rowClass"
              row-key="id"
              :tree-props="{children: 'secondList', hasChildren: 'hasSecondList'}"
              :cell-style="{padding: '5px 0'}"
              max-height="264px">
              <el-table-column prop="name" label="名称" min-width="100" v-if="item.flowRepttype != '6' && item.flowRepttype != '7'"></el-table-column>
              <el-table-column v-for="(val, id) in item.cfFlowReptcontList" :key="id" :prop="val.prop" :label="val.label" :align="(item.flowRepttype == '6' || item.flowRepttype == '7' )? 'center' : 'right'" :min-width="item.flowRepttype == '4' ? '275' : '160'">
                <template slot-scope="scope">
                  <div v-if="item.flowRepttype == '6' || item.flowRepttype == '7'">
                    <template v-if="val.worktype == 'b'">{{scope.row.b[val.prop]}}</template>
                    <template v-else-if="val.worktype == 'n'">{{scope.row.n[val.prop]}}</template>
                    <template v-else-if="val.worktype == 'p'">{{scope.row.p[val.prop]}}</template>
                    <template v-else>{{scope.row.b[val.prop]}}</template>
                  </div>
                  <div v-for="(el, cfFlowReptcontId) in scope.row.list" :key="cfFlowReptcontId" v-if="item.flowRepttype != '6' && item.flowRepttype != '7' && el.cfFlowReptcontId == val.cfFlowReptcontId">
                    <template v-if="item.flowRepttype != '4'">
                      <div v-if="val.labelType == '4'">{{el.reptValue | comdify}}</div>
                      <div v-else-if="val.labelType == '5'">{{el.reptValue | filter_handleTimeRange}}</div>
                      <div v-else-if="el.subdealtype == '70'" style="display:flex;justify-content: flex-end">
                        <div style="margin-right: 10px">
                          出勤
                          <span style="color:blue;cursor: pointer">{{el.reptValue}}</span>
                        </div>
                        <div>
                          异常
                          <span style="color:red;cursor: pointer">{{el.reptValue2}}</span>
                        </div>
                      </div>
                      <div v-else>{{el.reptValue}}</div>
                    </template>
                    <el-table
                      v-if="item.flowRepttype == '4'"
                      :data="el.workList"
                      tooltip-effect="dark"
                      style="width: 100%"
                      :header-cell-style="{'background': 'rgba(0, 0, 0, 0.89)', 'color': '#fff', padding: '5px 0'}" 
                      :row-style="{'background': 'rgba(0, 0, 0, 0.9)','color': '#fff'}"
                      :cell-style="{padding: '5px 0'}"
                    >
                      <el-table-column prop="name" label="名称" header-align="center" min-width="100">
                        <!-- <template slot-scope="scope1"> -->
                          <!-- <a class="link" @click="handleDetail(scope1.row)" style="user-select: none;">{{scope1.row.name}}</a> -->
                        <!-- </template> -->
                      </el-table-column>
                      <el-table-column prop="ptotal" label="金额" align="right" min-width="150" header-align="center">
                        <template slot-scope="scope1">
                          {{scope1.row.ptotal | comdify}}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 饼图 -->
          <div v-if="item.type == '4'">
            <div :id="'echart' + index" class="echart" style="height: 250px"></div>
          </div>
        </div>
        
      </div>
    </div>
    <el-dialog title="详细信息" :visible.sync="dialogDetailVisible" :modal="false" width="45%" top="200px" :lock-scroll="false" close-on-click-modal>
      <div style="color:white">
        <table cellSpacing="0" border="1" style="border: 1px solid rgba(36, 34, 99);width:100%;">
          <tr height="40px" style="background: rgba(36, 34, 99, 0.8);line-height:40px">
            <th>楼层</th>
            <th>总面积</th>
            <th>已租</th>
            <th>未租</th>
            <th>相关企业</th>
          </tr>
          <tr v-for="(item, index) in floorList" :key="index" height="40px">
            <td style="text-align:center;padding:0 10px">{{item.name}}</td>
            <td style="text-align:right;padding:0 10px">{{item.total}}</td>
            <td style="text-align:right;padding:0 10px">{{item.rented}}</td>
            <td style="text-align:right;padding:0 10px">{{item.toRent}}</td>
            <td style="padding:0 10px">
              <div v-for="(val, id) in item.companys" :key="id" class="name" @click="goProject(val)">{{val.companyName}}</div>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="" @click="dialogDetailVisible = false" style="">关 闭</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import url from '@/config/url'
export default {
  data() {
    return {
      time: null,
      generalInfo: {
        total: 0,
        toRent: 0,
        rented: 0,
        expired: 0,
      },
      // rentIncome: {
      //   amount: [],
      //   month: []
      // },
      incomeMonthly: {
        day: [],
        income: [],
        receivable: [],
        received: []
      },
      incomeMonthlyProperty: {
        day: [],
        income: [],
        receivable: [],
        received: []
      },
      comTypeRatio: [],
      arr1: [1, 2, 3, 4],
      newRent: [],
      expireRent: [],
      buildingInfo: [],
      orgId: JSON.parse(window.sessionStorage.getItem('loginUser')).orgId,
      showList: false,
      dialogDetailVisible: false,
      detail: {
        companys:[{companyName: "宿迁云锐信息科技文化发展有限公司", ofoWorkId: 5287},{companyName: "宿迁云锐信息科技文化发展有限公司", ofoWorkId: 5287}],
        floor: "E-2栋二楼",
        positionX: 42,
        positionY: 37,
        rented: 112.8,
        toRent: 1887.2
      },
      floorList: [],
      height: 0,
      user: JSON.parse(sessionStorage.getItem('loginUser')),
      comRank: {
        timeList: [],
      },
      comTrain: {
        time: [],
      },
      series2: [],
      series4: [],
      series5: [],
      series6: [],
      isDefault: true,
      cfDboard: {},
      cfDboardContList: []
    };
  },
  created () {
    
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        // console.log(window.innerWidth)
      })()
    };
  },
  async mounted() {
    document.querySelector('.contain').style.background = `url(${this.$base_url}${this.user.screenUrl})`
    document.querySelector('.contain').style.backgroundSize = `100% 100%`
    // setTimeout({
    this.getTime();
    // }, 1000)
    // 获取数据看板
    await this.$http.get(url.getCfDboard, {params: {type: '2'}}).then(async res => {
      if(res.data.code == 200) {
        if(res.data.data) {
          console.log(222);
          this.isDefault = false
          this.cfDboard = res.data.data.cfDboard
          document.querySelector('.contain').style.background = `url(${this.$base_url}${this.cfDboard.pic})`
          document.querySelector('.contain').style.backgroundSize = `100% 100%`
          this.cfDboardContList = res.data.data.cfDboardContList
          if(this.cfDboardContList.some(item => item.dataSourceType == 'rept')) {
            this.getCfOrgReptList()
          }
        }else { // 默认
          this.isDefault = true
          await this.getData()
          await this.getEchartData()
        }
        
      }
    })
    
    // this.echarts_1();
    this.echarts_2('echart2', 'incomeMonthly', 'day', this.series2);
    this.echarts_2('echart4', 'comRank', 'timeList', this.series4);
    this.echarts_2('echart5', 'comTrain', 'time', this.series5);
    this.echarts_2('echart6', 'incomeMonthlyProperty', 'day', this.series6);
    this.echarts_3();
  },
  methods: {
    async getCfOrgReptList() {
      await this.$http.get(url.getCfOrgReptList).then(res => {
        if(res.data.code == 200) {
          this.orgReptList = res.data.data
          this.cfDboardContList.forEach((item, index) => {
            // if(item.dataSourceType == 'rept') {
            let orgRept = this.orgReptList.find(val => val.cfFlowReptId == item.cforgreptId)
            item.orgRept = orgRept
            item.flowRepttype = orgRept.flowRepttype
            item.cfFlowReptcontList = orgRept.cfFlowReptcontList
            if(item.type == '1') {
              if(orgRept.flowRepttype == '1' || orgRept.flowRepttype == '2') {
                this.$http.get(url.getOrgRept, {params: {
                  cfFlowReptId: item.cforgreptId
                }}).then(res => {
                  if(res.data.code == 200) {
                    let xAxis = res.data.data.map(item => item.name)
                    let series = orgRept.cfFlowReptcontList.map(val => {
                      return {
                        ...val,
                        data: this.getData1(val, res.data.data),
                        type: 'bar',
                        name: val.label,
                        // label: labelOption,
                        emphasis: {
                          focus: 'series'
                        },
                      }
                    })
                    this.echarts_6(xAxis, series, 'echart' + index)
                  }
                })
              }else {
                let xAxis = ['活动一', '活动二', '活动三', '活动四', '活动五', '活动六', '活动七']
                let series = [
                  {
                    data: [120, 200, 150, 80, 70, 110, 130],
                    type: 'bar'
                  }
                ]
                this.echarts_6(xAxis, series, 'echart' + index)
              }
            }else if(item.type == '2') {
              if(orgRept.flowRepttype == '1' || orgRept.flowRepttype == '2') {
                this.$http.get(url.getOrgRept, {params: {
                  cfFlowReptId: item.cforgreptId
                }}).then(res => {
                  if(res.data.code == 200) {
                    let xAxis = res.data.data.map(item => item.name)
                    let series = orgRept.cfFlowReptcontList.map(val => {
                      return {
                        ...val,
                        name: val.label,
                        type: 'line',
                        data: this.getData1(val, res.data.data),
                        symbol: "none"
                      }
                    })
                    this.echarts_5(xAxis, series, 'echart' + index)
                  }
                })
              }else{
                let xAxis = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                let series = [
                  {
                    name: '电脑',
                    type: 'line',
                    // stack: 'Total',
                    data: [120, 132, 101, 134, 90, 230, 210],
                    symbol: "none"
                  },
                  {
                    name: '鼠标',
                    type: 'line',
                    // stack: 'Total',
                    data: [15, 182, 191, 234, 290, 330, 500],
                    symbol: "none"
                  },
                  {
                    name: '键盘',
                    type: 'line',
                    // stack: 'Total',
                    data: [330, 232, 201, 154, 190, 330, 410],
                    symbol: "none"
                  },
                ]
                this.echarts_5(xAxis, series, 'echart' + index)
              }
            }else if(item.type == '3') {
              this.$http.get(url.getOrgRept, {params: {
                cfFlowReptId: item.cforgreptId
              }}).then(res => {
                if(res.data.code == 200) {
                  item.tableData = res.data.data
                  if(orgRept.flowRepttype != '6' && orgRept.flowRepttype != '7') {
                    item.tableData.push({
                      name: '合计',
                      list: JSON.parse(JSON.stringify(item.cfFlowReptcontList)),
                      secondList: []
                    })
                    item.tableData[item.tableData.length - 1].list.forEach(item1 => {
                      item1.reptValue = 0
                      item1.reptValue2 = 0
                      item.tableData.forEach((val, index) => {
                        val.list.forEach(el => {
                          if(el.cfFlowReptcontId == item1.cfFlowReptcontId && index != item.tableData.length - 1) {
                            // console.log(el.reptValue);
                            item1.reptValue += Number(el.reptValue)
                          }
                        })
                        val.secondList.forEach(val1 => {
                          val1.list.forEach(el => {
                            if(el.cfFlowReptcontId == item1.cfFlowReptcontId) {
                              // console.log(el.reptValue);
                              item1.reptValue += Number(el.reptValue)
                              if(el.subdealtype == '70') {
                                item1.reptValue2 += Number(el.reptValue2)
                              }
                            }
                          })
                        })
                      })
                    });
                  }
                }
              })
            }else if(item.type == '4') {
              if(orgRept.flowRepttype == '1' || orgRept.flowRepttype == '2') {
                this.$http.get(url.getOrgRept, {params: {
                  cfFlowReptId: item.cforgreptId
                }}).then(res => {
                  if(res.data.code == 200) {
                    let series = res.data.data.map(val => {
                      let obj = val.list.find(el => el.cfFlowReptcontId == orgRept.cfFlowReptcontList[0].cfFlowReptcontId)
                      return {
                        name: val.name,
                        value: obj ? obj.reptValue : ''
                      }
                    })
                    // let series = orgRept.cfFlowReptcontList.map(val => {
                    //   return {
                    //     name: val.label,
                    //     value: 23
                    //   }
                    // })
                    this.echarts_4(series, 'echart' + index)
                  }
                })
              }else {
                let series = [
                  { value: 1048, name: '华为' },
                  { value: 735, name: '百度' },
                  { value: 580, name: '拼多多' },
                  { value: 484, name: '小米' },
                  { value: 300, name: 'oppo' }
                ]
                this.echarts_4(series, 'echart' + index)
              }
            }
            // item.cfFlowReptcontList = []
            
          })
        }
      })
    },
    
    getData1(item, tableData) {
      let arr = []
      // console.log(tableData);
      tableData.forEach(val => {
        let obj = val.list.find(el => el.cfFlowReptcontId == item.cfFlowReptcontId)
        if(obj) {
          arr.push(obj.reptValue)
        }else {
            arr.push('')
        }
      })
      return arr
    },
    echarts_4(series, echart) {
      var myChart = echarts.init(document.getElementById(echart));
      let option = {
        // title: {
        //   text: 'Referer of a Website',
        //   subtext: 'Fake Data',
        //   left: 'center',
        // },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: "5%",
          left: "1%",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            left: "left",
            center: ['60%', `center`],
            radius: ["40%", "80%"],
            data: series,
            label: {
              show: false,
              position: 'center'
            },
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      // }
      
    },
    echarts_5(xAxis, series, echart) {
      // console.log(echart);
      // 基于准备好的dom，初始化echarts实例
      // var myEchart = document.getElementsByClassName('echart');
      var myChart = echarts.init(document.getElementById(echart));
      // console.log(myEchart);
      // for(var i = 0; i < myEchart.length; i++) {
      //   var myChart = echarts.init(myEchart[i]);
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          left: "1%",
          textStyle: {
            color: "#fff",
          },
          icon: "roundRect",
        },
        // color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        grid: {
          left: "3%",
          right: "6%",
          bottom: "3%",
          top: "80",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxis,

          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",

          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: series
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      // }
      
    },
    // 柱状
    echarts_6(xAxis, series, echart) {
      var myChart = echarts.init(document.getElementById(echart));
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          left: "1%",
          textStyle: {
            color: "#fff",
          },
          icon: "roundRect",
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        grid:{
          // height: '60%',
          
          left: "3%",
          right: "6%",
          bottom: "3%",
          top: "80",
          containLabel: true,
        },
        series: series,
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      // }
      
    },
    rowClass({row, column, rowIndex, columnIndex}){
      if(row.name == '合计') {
        return 'fixed-row'
      }
    },
    handleView(item ,index) {
      this.buildingInfo[index].show = false
      this.floorList = item.floorList
      this.dialogDetailVisible = true
    },
    // 跳转活动
    goProject(row) {
      this.$router.push('/project/' + row.ofoitemId);
      let obj = {
        path: "/project/" + row.ofoitemId,
        name: "proDetail",
        label: "项目详情",
        ofoItemId: row.ofoitemId,
        children: []
      };
      this.$store.commit("selectMenu", obj);
    },
    handleMouseenter(index) {
      this.buildingInfo[index].show = true
    },
    handleMouseleave(index) {
      this.showList = false
      this.buildingInfo[index].show = false
    },
    async getData() {
      this.$http.get(url.generalInfo, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.generalInfo = res.data.data
        }
      })
      this.$http.get(url.newRent, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.newRent = res.data.data
        }
      })
      this.$http.get(url.expireRent, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.expireRent = res.data.data
        }
      })
      this.$http.get(url.buildingInfo, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.buildingInfo = res.data.data
          this.buildingInfo.forEach(item => {
            item.show = false
          })
        }
      })
    },
    async getEchartData() {
      // 租金收益情况
      await this.$http.get(url.incomeMonthly, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.incomeMonthly = res.data.data
          this.series2 = [
            {
              name: this.user.orgName == '青海华汇新能源有限公司' ? '销售金额' : "租金收入情况",
              type: "line",
              data: this.incomeMonthly.income,
              symbol: "none",
            },
            {
              name: this.user.orgName == '青海华汇新能源有限公司' ? '开票金额' : "欠缴租金情况",
              type: "line",
              data: this.incomeMonthly.receivable,
              symbol: "none",
            },
            {
              name: this.user.orgName == '青海华汇新能源有限公司' ? '收款金额' : "实收租金情况",
              type: "line",
              data: this.incomeMonthly.received,
              symbol: "none",
            },
          ]
        }
      })
      // 物业费用收益情况
      await this.$http.get(url.incomeMonthlyProperty, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.incomeMonthlyProperty = res.data.data
          this.series6 = [
            {
              name: this.user.orgName == '青海华汇新能源有限公司' ? '采购金额' : "物业费用收入情况",
              type: "line",
              data: this.incomeMonthlyProperty.income,
              symbol: "none",
            },
            {
              name: this.user.orgName == '青海华汇新能源有限公司' ? '入库金额' : "欠缴物业费用情况",
              type: "line",
              data: this.incomeMonthlyProperty.receivable,
              symbol: "none",
            },
            {
              name: this.user.orgName == '青海华汇新能源有限公司' ? '付款金额' : "实收物业费用情况",
              type: "line",
              data: this.incomeMonthlyProperty.received,
              symbol: "none",
            },
          ]
        }
      })
      // 企业考核情况
      await this.$http.get(url.comRank, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.comRank = res.data.data
          this.series4 = [
            {
              name: "考核完成企业数量",
              type: "line",
              data: this.comRank.passNumList
,
              symbol: "none",
            },
            {
              name: "核未达标企业数量",
              type: "line",
              data: this.comRank.notPassNumList,
              symbol: "none",
            },
          ]
        }
      })
      // 企业培育情况
      await this.$http.get(url.comTrain, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.comTrain = res.data.data
          this.series5 = [
            {
              name: "企业税收",
              type: "line",
              data: this.comTrain.companyTax
,
              symbol: "none",
            },
            {
              name: "企业产值",
              type: "line",
              data: this.comTrain.companyValue,
              symbol: "none",
            },
            {
              name: "就业人数",
              type: "line",
              data: this.comTrain.employeeCount,
              symbol: "none",
            },
            {
              name: "高企数量",
              type: "line",
              data: this.comTrain.highTechComCount,
              symbol: "none",
            },
            {
              name: "知识产权",
              type: "line",
              data: this.comTrain.intellectualPropertyCount,
              symbol: "none",
            },
            {
              name: "国科小数量",
              type: "line",
              data: this.comTrain.techSmallComCount,
              symbol: "none",
            },
          ]
        }
      })
      // 产业分类 
      await this.$http.get(url.comTypeRatio, {params: 
      {orgId: this.orgId}}).then(res => {
        if(res.data.code == 200) {
          this.comTypeRatio.push(res.data.data[0], res.data.data[1], res.data.data[2])
          this.height = this.comTypeRatio.length * 20
        }
      })
    },
    getTime() {
      var t = null;
      t = setTimeout(this.getTime, 1000); //開始运行
      clearTimeout(t); //清除定时器
      let dt = new Date();
      var y = dt.getFullYear();
      var mt = dt.getMonth() + 1;
      var day = dt.getDate();
      var h = dt.getHours(); //获取时
      var m = dt.getMinutes(); //获取分
      var s = dt.getSeconds(); //获取秒
      this.time =
        y +
        "/" +
        mt +
        "/" +
        day +
        " " +
        this.add0(h) +
        ":" +
        this.add0(m) +
        ":" +
        this.add0(s) +
        "";
      // document.getElementById("showTime").innerHTML = y + "/" + mt + "/" + day + " " + add0(h) + ":" + add0(m) + ":" + add0(s) + "";
      t = setTimeout(this.getTime, 1000); //设定定时器，循环运行
    },
    add0(n) {
      if (n < 10) {
        return "0" + n;
      } else {
        return n;
      }
    },
    // echarts_1() {
    //   // 基于准备好的dom，初始化echarts实例
    //   let myChart = echarts.init(document.getElementById("echart1"));
    //   // var myChart = echarts.init(document.getElementById('echart1'), null, {
    //   //     height: 300
    //   // });
    //   let option = {
    //     title: {
    //       // text: '租金',
    //     },
    //     grid: {
    //       // 让图表占满容器
    //       top: "10px",
    //       left: "60px",
    //       right: "20px",
    //       bottom: "20px",
    //     },
    //     tooltip: {
    //       trigger: "item",
    //       triggerOn: "click",
    //       formatter: "{b}:{c}",
    //     },
    //     xAxis: {
    //       type: "category",
    //       boundaryGap: false,
    //       // data: this.rentIncome.month,
    //       axisLabel: {
    //         show: true,
    //         textStyle: {
    //           color: "#fff",
    //         },
    //       },
    //     },
    //     yAxis: {
    //       type: "value",
    //       name: "租金",
    //       axisLabel: {
    //         show: true,
    //         textStyle: {
    //           color: "#fff",
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         // data: this.rentIncome.amount,
    //         label: {
    //           show: true,
    //         },
    //         type: "line",
    //         color: "#fff",
    //         smooth: true,
    //         lineStyle: {
    //           width: 0,
    //         },
    //         areaStyle: {
    //           opacity: 0.8,
    //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //             {
    //               offset: 0,
    //               color: "rgb(0, 221, 255)",
    //             },
    //             {
    //               offset: 1,
    //               color: "rgb(77, 119, 255)",
    //             },
    //           ]),
    //         },
    //       },
    //     ],
    //   };
    //   // 使用刚指定的配置项和数据显示图表。
    //   myChart.setOption(option);
    //   window.addEventListener("resize", function () {
    //     myChart.resize();
    //   });
    // },
    echarts_2(dom, arr, xKey, series) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(dom));
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          left: "1%",
          textStyle: {
            color: "#fff",
          },
          icon: "roundRect",
        },
        // color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        grid: {
          left: "3%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this[arr][xKey],

          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",

          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: series,
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    echarts_3() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("echart3"));
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          // top: '5%',
          left: "1%",
          textStyle: {
            color: "#fff",
          },
          orient: "vertical",
        },
        // color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        series: [
          {
            name: "企业种类对比",
            type: "pie",
            radius: ["40%", "65%"],
            avoidLabelOverlap: false,
            left: "left",
            center: ['center', `${this.height + 120}`],
            // itemStyle: {
            //     borderRadius: 10,
            //     borderColor: '#fff',
            //     borderWidth: 2
            // },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
                color: "#fff",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.comTypeRatio,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
img {
  border: none;
  max-width: 100%;
}
.contain {
  color: #fff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}
.head {
  position: relative;
  /* position: fixed;
  width: calc(100% - 75px); */
  height: 90px;
  background: url(@/assets/images/screen/topbg.png) center bottom no-repeat;
  background-size: 100% 100%;
}
.head h2 {
  font-size: 30px;
  text-align: center;
  line-height: 90px;
  color: #daf9ff;
}
.head .time {
  position: absolute;
  right: 40px;
  line-height: 40px;
  top: 0;
  opacity: 0.7;
}
.mainbox {
  height: 100%;
  /* background-color: pink;
  opacity: .7; */
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  flex-wrap: wrap;
}
.boxb {
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
    /* background-color: #f19944; */
    color: #000;
  }
  ::v-deep .el-table__empty-block {
    
    color: #fff;
    background: rgba(0, 0, 0, 0.9);
    .el-table__empty-text {
      color: #fff;
    }
  }
  ::v-deep .el-table{
    .el-table__body {
      // 吸底
      .fixed-row{
        /* display: inline-block; */
        position: sticky;
        bottom: 0;
        width: 100%;
        background: #f5f7fa;
      }
    }
  }
}
.boxb:nth-child(2n) {
  display: flex;
  justify-content: flex-end;
}
.nav {
  height: 100%;
  /* background-color: yellow;
  opacity: .7; */
  width: 22%;
  min-width: 310px;
  max-width: 330px;
}
.box {
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  /* height: 33%; */
}
.tit {
  display: flex;
  align-items: flex-end;
}
.tit span {
  background: url(@/assets/images/screen/line1.png) no-repeat bottom right;
  white-space: nowrap;
  padding-bottom: 10px;
  padding-right: 20px;
  font-size: 20px;
}
.tit p {
  background: url(@/assets/images/screen/line2.png) no-repeat bottom right;
  width: 100%;
  height: 13px;
  margin-bottom: 5px;
  opacity: 0.5;
}
.boxnav {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  /* align-content: space-between; */
}
.box-item {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* margin-right: 12px; */
}
.little-title {
  font-size: 14px;
}
.box-item2 .icon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
@font-face{font-family:electronicFont;src:url(@/assets/font/DS-DIGIT.TTF)}
.box-item2 em {
  background: linear-gradient(0deg, #45d3fd, #45d3fd, #61ddb1, #61ddb1);
  font-style: normal;
  background-size: cover;
  font-family: electronicFont;
  font-size: 26px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}
.detail {
  position: absolute;
  width: 300px;
  /* height: 300px; */
  background-color: rgba(2, 6, 69, 0.5);
  left: 20px;
  top: 0px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(2, 6, 69);
  /* display: none; */
  z-index: 2;
  

}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background-color: white;
  /* left: 47%;
  top: 50%; */
}
/* .circle:hover .detail {
  display: block;
} */
.detail-item {
  display: flex;
  margin: 10px 0;
}
.detail-left {
  width: 80px;
}
.detail-right {
  flex: 1;
}
.box {
  margin-bottom: 20px;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
}
.box-item1 {
  display: flex;
  font-size: 13px;
  align-items: center;
  background-color: rgba(36, 34, 99, 0.8);
  margin: 10px;
  border-radius: 5px;
  min-height: 50px;
  /* width: 100%; */
}
.box-item-left {
  width: 20px;
  background: #07a6ff;
  text-align: center;
  border-radius: 5px;
  min-height: 50px;
  display: flex;
  align-items: center;
}
.box-item-right {
  flex: 1;
  margin: 0 10px;

  width: calc(100% - 40px);
}
.box-item-right-item-left {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.box-item-right-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  width: 100%;
}
.box-item-right-item-right {
  min-width: 68px;
  text-align: right;
}
::v-deep .el-dialog {
  background:rgba(0,0,0,0.5);
}
::v-deep .el-dialog__header span {
  color: white;
}
::v-deep .el-dialog__close {
  color: white !important;
}
.name {
    cursor: pointer;
    line-height: 25px;
  }
  .name:hover {
    color: rgb(77, 119, 255);
  }
</style>