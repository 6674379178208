<template>
  <div>
    <template v-for="(item, index) in formList">
      <el-row :key="index" v-if="index % 2 == 0" :gutter="gutter">
        <el-col :span="12">
          <!-- <el-form-item :label="item.dimeName + '*'" :prop="item.cbDimeId + ''"> -->
          <el-form-item :label="item.dimeName + '*'" :prop="'dataSourceConfig.' + index + '.dimeValue'" :rules="item.isRequired == '1' ? [{ required: true, message: item.dimeName + '不能为空', trigger: 'change' }] : []" ref="item">
            <template slot="label">
              <template>
                {{item.dimeName + '*'}}
              </template>
              <el-button v-if="item.editType == '3'" icon="el-icon-edit-outline" size="small" type="text" @click="editCbussDime(item)" style="position:absolute;top:2px;z-index:99"></el-button>
            </template>
          <!-- <el-form-item :label="item.dimeName + '*'" :prop="item.dimeName" :required="item.isRequired == '1' ? true : false"> -->
            <!-- 1 文本框 -->
            <el-input 
              v-if="item.editType == '1'" 
              :placeholder="'请输入' + item.dimeName" 
              v-model="item['dimeValue']"
              clearable
            ></el-input>
            <!-- 2 文本域 -->
            <el-input 
              v-if="item.editType == '2'" 
              type="textarea" 
              :placeholder="'请输入' + item.dimeName" 
              v-model="item['dimeValue']"
              clearable
            ></el-input>
            <!-- 3 下拉框 -->
            <el-select 
              v-if="item.editType == '3'" 
              v-model="item['dimeValue']" 
              style="width: 100%"
              clearable
              :placeholder="'请选择' + item.dimeName"
              filterable >
              <el-option 
                v-for="(drop, id) in JSON.parse(item.options)" 
                :key="id" 
                :label="drop.optionLabel" 
                :value="drop.optionValue + ''"
              ></el-option>
            </el-select>
            <!-- 4 数字 -->
            <el-input-number 
              v-if="item.editType == '4'" 
              v-model="item['dimeValue']"
              label="描述文字"
              style="width: 100%"
              controls-position="right"
              class="right-input"
            ></el-input-number>
            <!-- <el-input 
              v-if="item.editType == '4'" 
              type="number" 
              :placeholder="'请输入' + item.dimeName" 
              v-model="item['dimeValue']"
              class="right-input"
              clearable
            ></el-input> -->
            <!-- 5 日期 -->
            <el-date-picker
              v-if="item.editType == '5'" 
              v-model="item['dimeValue']" 
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 100%">
            </el-date-picker>
            <!-- 6 单选框 -->
            <template
              v-if="item.editType == '6'">
              <br/>
              <el-radio 
                v-for="(val, index) in JSON.parse(item.options)" 
                :key="index"
                v-model="item['dimeValue']" 
                :label="val.optionValue + ''">{{val.optionLabel}}</el-radio>
            </template>
            <!-- 7 多选框 -->
            <template v-if="item.editType == '7'">
              <el-select
                v-if="item.editType == '7'"
                v-model="item['dimeValue']"
                multiple
                clearable
                filterable
                style="width: 100%"
                :placeholder="'请选择' + item.dimeName"
                @change="change(item, $event, index)">
                <el-option 
                  v-for="(drop, id) in JSON.parse(item.options)" 
                  :key="id" 
                  :label="drop.optionLabel" 
                  :value="drop.optionValue"
                ></el-option>
              </el-select>
            </template>
            <!-- 8 附件 -->
            <template v-if="item.editType == '8'">
              <br/>
              <Upload v-model="item['dimeValue']" :isShowTip="false" />
            </template>
            <!-- 9 日期时间 -->
            <el-date-picker
              v-if="item.editType == '9'"
              v-model="item['dimeValue']"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%">
            </el-date-picker>
            <!-- 10 金额 -->
            <el-input
              v-if="item.editType == '10'"
              v-model="item['dimeValue']"
              class="right-input"
              @input="item['dimeValue']=item['dimeValue'].replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
              @blur="comdify(item, item['dimeValue'], index)"
              @focus="delComdify(item, item['dimeValue'], index)"
              clearable>
            </el-input>
            <!-- 11 百分比 -->
            <!-- <el-input
              v-if="item.editType == '11'"
              v-model="item['dimeValue']"
              :placeholder="'请输入' + item.dimeName + '(只填数字)'"
              class="right-input"
              @input="item['dimeValue'] = item['dimeValue'].replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')">
              <template slot="append">%</template>
            </el-input> -->
            <el-input
              v-if="item.editType == '11'"
              v-model="item['dimeValue']"
              class="right-input">
              <template slot="append">%</template>
            </el-input>
            <!-- 12 选择资源 -->
            <el-select 
              v-if="item.editType == '12'" 
              style="width: 100%"
              v-model="item['dimeValue']"
              clearable
              filterable
              multiple
              :placeholder="'请选择' + item.dimeName"
            >
              <el-option 
                v-for="(drop, id) in item.options" 
                :key="id" 
                :label="drop.resourceName" 
                :value="drop.ofrResourceId"
              ></el-option>
            </el-select>
            <!-- 13 选择内容 -->
            <el-select 
              v-if="item.editType == '13'" 
              style="width: 100%"
              v-model="item['dimeValue']"
              clearable
              filterable
              multiple
              :placeholder="'请选择' + item.dimeName"
            >
              <el-option-group
                v-for="(group, id1) in item.options"
                :key="id1"
                :label="group.resourceName">
                <el-option
                  v-for="(drop, id) in group.ofrThingList"
                  :key="id"
                  :label="drop.name"
                  :value="drop.ofrThingId">
                </el-option>
              </el-option-group>
            </el-select>
            <!-- 14 选择项目 -->
            <el-select 
              v-if="item.editType == '14'" 
              style="width: 100%"
              v-model="item['dimeValue']"
              clearable
              filterable
              multiple
              :placeholder="'请选择' + item.dimeName"
            >
              <el-option-group
                v-for="(group, id1) in item.options"
                :key="id1"
                :label="group.showName">
                <el-option
                  v-for="(drop, id) in group.ofoFlowItemList"
                  :key="id"
                  :label="drop.name"
                  :value="drop.ofoItemId">
                </el-option>
              </el-option-group>
            </el-select>
            <treeselect 
              v-if="item.editType == '20'" 
              style="width: 97%"
              v-model="item['dimeValue']"
              :options="item.options"
              :normalizer="normalizer"
              :placeholder="'请选择' + item.dimeName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="index + 1 < formList.length">
          <el-form-item :label="formList[index + 1].dimeName + '*'" :prop="'dataSourceConfig.' + (index + 1) + '.dimeValue'" :rules="formList[index + 1].isRequired == '1' ? [{ required: true, message: formList[index + 1].dimeName + '不能为空', trigger: 'change' }] : []">
            <div slot="label">
              {{formList[index + 1].dimeName + '*'}}
              <!-- <el-button v-if="formList[index + 1].editType == '3'" icon="el-icon-edit-outline" size="small" type="text"></el-button> -->
              <el-button v-if="formList[index + 1].editType == '3'" icon="el-icon-edit-outline" size="small" type="text" @click="editCbussDime(formList[index + 1])" style="position:absolute;top:2px;z-index:99"></el-button>
            </div>
            <!-- 文本框 -->
            <el-input 
              v-if="formList[index + 1].editType == '1'" 
              :placeholder="'请输入' + formList[index + 1].dimeName"  
              v-model="formList[index + 1]['dimeValue']"
              clearable
              style="width: 97%"
            ></el-input>
            <!-- 文本域 -->
            <el-input 
              v-if="formList[index + 1].editType == '2'" 
              type="textarea" 
              :placeholder="'请输入' + formList[index + 1].dimeName" 
              v-model="formList[index + 1]['dimeValue']"
              clearable
              style="width: 97%"
            ></el-input>
            <!-- 下拉框 -->
            <el-select 
              v-if="formList[index + 1].editType == '3'" 
              style="width: 97%"
              v-model="formList[index + 1]['dimeValue']"
              clearable
              filterable
              :placeholder="'请选择' + formList[index + 1].dimeName"
            >
              <el-option 
                v-for="(drop, id) in JSON.parse(formList[index + 1].options)" 
                :key="id" 
                :label="drop.optionLabel" 
                :value="drop.optionValue + ''"
              ></el-option>
            </el-select>
            <!-- 数字 -->
            <el-input-number 
              v-if="formList[index + 1].editType == '4'" 
              v-model="formList[index + 1]['dimeValue']"
              style="width: 97%"
              controls-position="right"
              class="right-input"
            ></el-input-number>
            <!-- <el-input 
              v-if="formList[index + 1].editType == '4'" 
              type="number" 
              v-model="formList[index + 1]['dimeValue']"
              style="width: 97%"
              class="right-input"
              clearable
            ></el-input> -->
            <!-- 日期 -->
            <el-date-picker
              v-if="formList[index + 1].editType == '5'" 
              v-model="formList[index + 1]['dimeValue']" 
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 97%">
            </el-date-picker>
            <!-- 单选框 -->
            <template
              v-if="formList[index + 1].editType == '6'">
              <br/>
              <!-- <el-input v-model="formList[index + 1]['dimeValue']"></el-input> -->
              <el-radio 
                v-for="(val, id) in JSON.parse(formList[index + 1].options)" 
                :key="id"
                v-model="formList[index + 1]['dimeValue']" 
                :label="val.optionValue + ''">{{val.optionLabel}}</el-radio>
            </template>
            <!-- 多选框 -->
            <template v-if="formList[index + 1].editType == '7'">
              <el-select
                v-model="formList[index + 1]['dimeValue']"
                multiple
                filterable
                clearable
                style="width: 97%"
                :placeholder="'请选择' + formList[index + 1].dimeName"
                @change="change(formList[index + 1], $event, (index + 1))">
                <el-option 
                  v-for="(drop, id) in JSON.parse(formList[index + 1].options)" 
                  :key="id" 
                  :label="drop.optionLabel" 
                  :value="drop.optionValue"
                ></el-option>
              </el-select>
            </template>
            <!-- 上传附件 -->
            <template v-if="formList[index + 1].editType == '8'">
              <br/>
              <Upload v-model="formList[index + 1]['dimeValue']" :isShowTip="false" />
            </template>
            
            <!-- 日期时间 -->
            <el-date-picker
              v-if="formList[index + 1].editType == '9'"
              v-model="formList[index + 1]['dimeValue']"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 97%">
            </el-date-picker>
            
            <!-- 10 金额 -->
            <el-input
              v-if="formList[index + 1].editType == '10'"
              v-model="formList[index + 1]['dimeValue']"
              class="right-input"
              @input="formList[index + 1]['dimeValue']=formList[index + 1]['dimeValue'].replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
              @blur="comdify(formList[index + 1], formList[index + 1]['dimeValue'], (index + 1))"
              @focus="delComdify(formList[index + 1], formList[index + 1]['dimeValue'], (index + 1))"
              clearable
              style="width: 97%">
            </el-input>
            <!-- 11 百分比 -->
            <!-- <el-input
              v-if="formList[index + 1].editType == '11'"
              v-model="formList[index + 1]['dimeValue']"
              :placeholder="'请输入' + formList[index + 1].dimeName + '(只填数字)'"
              class="right-input"
              @input="formList[index + 1]['dimeValue'] = formList[index + 1]['dimeValue'].replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')">
              <template slot="append">%</template>
            </el-input> -->
            <el-input
              v-if="formList[index + 1].editType == '11'"
              v-model="formList[index + 1]['dimeValue']"
              class="right-input"
              style="width: 97%">
              <template slot="append">%</template>
            </el-input>
            <!-- 12 选择资源 -->
            <el-select 
              v-if="formList[index + 1].editType == '12'" 
              style="width: 97%"
              v-model="formList[index + 1]['dimeValue']"
              clearable
              filterable
              multiple
              :placeholder="'请选择' + formList[index + 1].dimeName"
            >
              <el-option 
                v-for="(drop, id) in formList[index + 1].options" 
                :key="id" 
                :label="drop.resourceName" 
                :value="drop.ofrResourceId"
              ></el-option>
            </el-select>
            <!-- 13 选择内容 -->
            <el-select 
              v-if="formList[index + 1].editType == '13'" 
              style="width: 97%"
              v-model="formList[index + 1]['dimeValue']"
              clearable
              filterable
              multiple
              :placeholder="'请选择' + formList[index + 1].dimeName"
            >
              <el-option-group
                v-for="(group, id1) in formList[index + 1].options"
                :key="id1"
                :label="group.resourceName">
                <el-option
                  v-for="(drop, id) in group.ofrThingList"
                  :key="id"
                  :label="drop.name"
                  :value="drop.ofrThingId">
                </el-option>
              </el-option-group>
            </el-select>
            <!-- 14 选择项目 -->
            <el-select 
              v-if="formList[index + 1].editType == '14'" 
              style="width: 97%"
              v-model="formList[index + 1]['dimeValue']"
              clearable
              filterable
              multiple
              :placeholder="'请选择' + formList[index + 1].dimeName"
            >
              <el-option-group
                v-for="(group, id1) in formList[index + 1].options"
                :key="id1"
                :label="group.showName">
                <el-option
                  v-for="(drop, id) in group.ofoFlowItemList"
                  :key="id"
                  :label="drop.name"
                  :value="drop.ofoItemId">
                </el-option>
              </el-option-group>
            </el-select>
            <treeselect 
              v-if="formList[index + 1].editType == '20'" 
              style="width: 97%"
              v-model="formList[index + 1]['dimeValue']"
              :options="formList[index + 1].options"
              :normalizer="normalizer"
              :placeholder="'请选择' + formList[index + 1].dimeName"
            />
            <!-- <el-cascader 
              v-if="formList[index + 1].editType == '20'" 
              style="width: 97%"
              v-model="formList[index + 1]['dimeValue']"
              :props="{label: 'optionLabel', value: 'optionValue'}"
              :options="JSON.parse(formList[index + 1].options)"
              :show-all-levels="false">
            </el-cascader> -->
          </el-form-item>
        </el-col>
      </el-row>
    </template>
    
    <!-- 多行编辑 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%"
      top="59px">
      <div style="margin-bottom:10px">
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="addOptions">添加</el-button>
        <el-button size="mini" type="danger" icon="el-icon-delete" @click="delOptions" :disabled="checkedOption.length != 1">删除</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%;overflow:auto"
        @selection-change="handleSelectionChange"
        height="calc(100vh - 334px)"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="optionValue"
          label="序号"
          width="80">
        </el-table-column>
        <el-table-column
          prop="optionLabel"
          label="名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.optionLabel"/>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSelect">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import Upload from '@/components/upload/index.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    components: {
      Treeselect,
      Upload,
    },
    props: {
      formList: {
        type: Array,
      },
      gutter: Number,
      ruleForm: {
        type: Object,
        default: () => ({
          dataSourceConfig: []
        })
      },
    },
    data() {
      return {
        fileList: [
        ],
        files: '',
        // value0: []
        checkList: [],
        testModel:{studentsInfos: []},
        title: '',
        dialogVisible: false,
        tableData: [],
        checkedOption: [],
        cbdId: ''
      }
    },
    created() {
      // console.log(111);
      // console.log(this.formList);
      // this.getList()
    },
    mounted() {
      // console.log(3);
      this.$nextTick(() => {
        // console.log(this.$refs);
      })
    },
    updated () {
      // console.log(22222222);
    },
    watch: {
    },
    methods: {
      addOptions() {
        let maxValue = Math.max.apply(Math, this.tableData.map(item => item.optionValue));
        let obj = {};
        obj.optionLabel = "";
        obj.optionValue = Number(maxValue + 1);
        this.tableData.push(obj);
      },
      delOptions() {
        if (this.checkedOption.length === 0) {
          this.$message.error("请先选择要删除的选项");
        } else {
          const optionList = this.tableData;
          const checkedOption = this.checkedOption;
          this.$http.get(url.canDeleteDimeOption, {params: {cbdId: this.cbdId, dimeValue: checkedOption[0]}}).then(res => {
            // console.log(res);
            if(res.data.code == 200) {
              this.tableData = optionList.filter(function(item) {
                return checkedOption.indexOf(item.optionValue) === -1
              });
            }
          })
          
        }
      },
      handleSelectionChange(selection) {
        this.checkedOption = selection.map(item => item.optionValue)
      },
      editCbussDime(item) {
        // console.log(JSON.parse(JSON.stringify(item)));
        this.title = `修改${item.dimeName}`
        this.tableData = JSON.parse(item.options)
        this.cbdId = item.cbdId
        this.dialogVisible = true
      },
      submitSelect() {
        this.$http.post(url.editCbussDimeOptions, {cbdId: this.cbdId, options: JSON.stringify(this.tableData)}).then(res => {
          if(res.data.code == 200) {
            this.$message.success(res.data.msg);
            let index = this.formList.findIndex(item => item.cbdId == this.cbdId)
            this.formList[index].options = JSON.stringify(this.tableData)
            this.dialogVisible = false
          }
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // console.log(node);
        
        this.$nextTick(() => {
          if (node.children && !node.children.length) {
            delete node.children;
          }
          // console.log({
          //   id: node.optionValue,
          //   label: node.optionLabel,
          //   children: node.children
          // });
          return {
            id: node.optionValue,
            label: node.optionLabel,
            children: node.children
          };
          
        })
      },
      getList() {
        if(this.formList.length) {
          this.formList.forEach(item => {
            if(item.editType == '12') {
              this.$http.get(url.getResTypeAndList, {params: {
                cbRestypeId: item.resourceType
              }}).then(res => {
                item.options = res.data.data.list
              })
            }
          })
        }
       
      },
      change(item, value, index) {
        this.$set(this.formList, index, item)
      },
      change1(item, value, index) {
        let arr = [...this.formList[index].dimeValue]
        if (arr.indexOf(value) > -1) {
          arr.splice(arr.indexOf(value), 1)
        } else {
          arr.push(value)
        }
        item.dimeValue = arr
        this.$set(this.formList, index, item)
      },
      // 添加千分位
      comdify(item, n, index) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        item.dimeValue = `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
        this.$set(this.formList, index, item)
      },
      // 去掉千分位
      delComdify(item, n, index) {
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        item.dimeValue = numS.replace(/,/gi, '');
        this.$set(this.formList, index, item)
      },
      // 添加千分位
      comdify1(item, n, index) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        item.dimeValue = `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
        this.$set(this.formList, index, item)
      },
      // 去掉千分位
      delComdify1(item, n, index) {
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        item.dimeValue = numS.replace(/,/gi, '');
        this.$set(this.formList, index, item)
      },
    },
  }
</script>

<style lang="scss" scoped>
.right-input {
  ::v-deep .el-input__inner {
    text-align: right;
  }
}
</style>