<template>
  <div>
    <div style="text-align:center;font-weight:900;font-size: 16px;margin-bottom:16px">{{ofoFlowWork.kinddocutypeName}}</div>
    <template v-if="!disabled">
      <!-- <el-form :v-model="ruleForm" size="small"> -->
      <template v-if="dblockList.length">
        <div v-for="(item, index) in dblockList" :key="index" class="box">
          <div class="title" style="margin-bottom: 10px">{{item.blockName}}</div>
          <Form v-if="documentsList" :formList="documentsList.filter(val => val.dblockId == item.docuBlockId)" :gutter="20" :ruleForm="ruleForm"/>
        </div>
      </template>
      <template v-else>
        <Form v-if="documentsList" :formList="documentsList" :gutter="20" :ruleForm="ruleForm"/>
      </template>
      <!-- </el-form> -->
    </template>
    <template v-if="disabled">
      <template v-if="dblockList.length">
        <div v-for="(item, index) in dblockList" :key="index" class="box">
          <div class="title" style="margin-bottom: 10px">{{item.blockName}}</div>
            <el-descriptions :column="3">
              <el-descriptions-item v-for="(item, index) in documentsList.filter(val => val.dblockId == item.docuBlockId)" :key="index" :label="item.dimeName + '*'" :labelStyle="{'max-width': '100px'}" :contentStyle="{'background': item.dimeText ? 'rgb(240, 250, 255)' : '#fff'}">
                <template v-if="item.editType != '8'">{{item.dimeText}}</template>
                <template v-else>
                  <fileList v-if="item.dimeText" :fileList="JSON.parse(item.dimeText)" />
                </template>
              </el-descriptions-item>
            </el-descriptions>
        </div>
      </template>
      <template v-else>
        <el-descriptions :column="3">
          <el-descriptions-item v-for="(item, index) in documentsList" :key="index" :label="item.dimeName + '*'" :labelStyle="{'max-width': '100px'}" :contentStyle="{'background': item.dimeText ? 'rgb(240, 250, 255)' : '#fff'}">
            <template v-if="item.editType != '8'">{{item.dimeText}}</template>
            <template v-else>
              <fileList v-if="item.dimeText" :fileList="JSON.parse(item.dimeText)" />
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </template>
      
    </template>
  </div>
</template>

<script>

import DimeTag from '@/components/DimeTag.vue'

import url from '@/config/url'
import Form from '@/components/Form.vue'
  export default {
    props: {
      disabled: Boolean,
      documentsList: Array,
      ofoFlowWork: Object,
      ruleForm: {
        type: Object,
        default: () => ({
          dataSourceConfig: []
        })
      },
    },
    data() {
      return {
        baseUrl: this.$base_url,
        dblockList: []
      }
    },
    mounted () {
      // console.log(888888);
      
      this.getdblockList()
    },
    methods: {
      getdblockList() {
        console.log(this.ofoFlowWork.kinddocutype);
        
        if(this.ofoFlowWork.kinddocutype) {
          // console.log(1111);
          
          this.$http.get(url.dblockList + this.ofoFlowWork.kinddocutype).then(res => {
            if(res.data.code == 200) {
              this.dblockList = res.data.data
            }
          })
        }
        
      }
    },
    components: {
      DimeTag,
      Form
    },
    watch: {
      'ofoFlowWork': {
        handler(newValue, oldValue) {

          // console.log(1234365);
          
          this.getdblockList()
        },
        deep: true
      },
    }
  }
</script>

<style lang="scss" scoped>
.box {
  border-left: 1px solid #ddd;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    box-shadow: 2px 2px 8px 2px #ddd;
}
.title {
  border-left: 3px solid #0c75e5;
  padding-left: 3px;
}
/* ::v-deep .el-descriptions-item__label {
  min-width: 40px;
}
::v-deep .el-descriptions-item__content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */
</style>