<template>
  <div class="tab1">
    <!-- <el-form :inline="true" :model="thingFormInline" class="demo-form-inline" size="small" @submit.native.prevent>
      <el-form-item>
        <strong slot="label">名称</strong>
        <el-input v-model="thingFormInline.name" placeholder="请输入名称" clearable @keyup.enter.native="inquire" @clear="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form> -->
    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd({bomId: 0})" v-if="judgeButton('add')">新增</el-button>
    <el-button type="info" plain size="small" icon="el-icon-copy-document" @click="handleCopy" v-if="judgeButton('add')">复制</el-button>
    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleEditBatch({bomId: 0})" v-if="judgeButton('edit')">批量编辑</el-button>
    <el-button type="danger" plain size="small" icon="el-icon-delete" @click="handleDelBatch" v-if="judgeButton('delete')" :disabled="!ids.length">批量删除</el-button>
    <el-button type="warning" plain size="small" @click="handleImport" icon="el-icon-upload2" v-if="judgeButton('add')">导入</el-button>
    <!-- <el-button type="success" plain size="small" icon="el-icon-edit" :disabled="!edit" @click="handleEdit">编辑</el-button>
    <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button> -->
    <el-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%;margin-top:16px"
      @selection-change="handleSelectionChange"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      v-loading="loading"
      row-key="bomId"
      default-expand-all
      :tree-props="{children: 'children'}"
      :summary-method="getSummaries"
      show-summary>
      <el-table-column
        type="selection"
        width="75">
      </el-table-column>
      <el-table-column
        type="index"
        width="55"
        label="行号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="资源名称">
        <!-- <template slot-scope="scope">
          <a @click="handleView(scope.row)" class="link">{{scope.row.name}}</a>
        </template> -->
      </el-table-column>
      <el-table-column
        label="数量"
        prop="quantity"
        width="120"
        align="right"
        header-align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.quantity"
            size="mini"
            class="right-input"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="金额"
        prop="price"
        width="120"
        align="right"
        header-align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.price"
            size="mini"
            class="right-input"
          ></el-input>
        </template>
        <!-- <template slot-scope="scope">
          {{scope.row.price | comdify('no')}}
        </template> -->
      </el-table-column>
      <el-table-column
        label="损耗率"
        prop="lossRate"
        width="125">
        <template slot-scope="scope">
          <!-- {{scope.row.lossRate ? scope.row.lossRate + '%' : ''}} -->
          <el-input
            v-model="scope.row.lossRate"
            size="mini"
            class="right-input"
          >
            <template slot="append">%</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        prop="remark"
        width="140"
        show-overflow-tooltip>
        <template slot-scope="scope">
          <!-- {{scope.row.lossRate ? scope.row.lossRate + '%' : ''}} -->
          <el-input
            v-model="scope.row.remark"
            size="mini"
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="jc"
        label="简称"
        min-width="200">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.jc"
            size="mini"
            clearable
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="sort"
        label="排序">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.sort"
            size="mini"
            clearable
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="170">
        <template slot-scope="scope">
          <el-button
            size="mini" icon="el-icon-plus" type="text"
            @click="handleAdd(scope.row)" v-if="judgeButton('add')">新增</el-button>
          <el-button
            size="mini" icon="el-icon-edit" type="text"
            @click="handleEdit(scope.row)" v-if="judgeButton('edit')">编辑</el-button>
          <el-button
            size="mini" icon="el-icon-delete" type="text"
            @click="handleDel(scope.row)" v-if="judgeButton('delete')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        style="max-height: 50vh; overflow-y: auto;overflow-x:hidden"
        label-width="60px"
        size="small"
      >
        <!-- <el-form-item label="资源" prop="ofrResourceId"> -->
        <el-form-item v-if="ruleForm.bomId" label="资源" prop="ofrResourceId">
          <el-select v-model="ruleForm.ofrResourceId" placeholder="请选择资源" filterable style="width: 100%">
            <el-option-group
              v-for="(group, index) in resTreeList"
              :key="index"
              :label="group.name">
              <el-option
                v-for="item in group.resourceList"
                :key="item.ofrResourceId"
                :label="item.remarks"
                :value="item.ofrResourceId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="资源" prop="ofrResourceIds">
          <el-select v-model="ruleForm.ofrResourceIds" placeholder="请选择资源" filterable style="width: 100%" multiple>
            <el-option-group
              v-for="(group, index) in resTreeList"
              :key="index"
              :label="group.name">
              <el-option
                v-for="item in group.resourceList"
                :key="item.ofrResourceId"
                :label="item.remarks"
                :value="item.ofrResourceId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="父级" prop="pid">
          <treeselect v-model="ruleForm.pid" :options="options" :normalizer="normalizer" placeholder="请选择父级" />
        </el-form-item>
        <el-form-item label="损耗率" prop="lossRate">
          <el-input
            v-model="ruleForm.lossRate"
            type="number"
            :placeholder="`请输入损耗率`"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="金额" prop="price">
          <el-input
            v-model="ruleForm.price"
            :placeholder="`请输入金额`"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="quantity">
          <el-input
            v-model="ruleForm.quantity"
            :placeholder="`请输入数量`"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="remark">
          <el-input
            v-model="ruleForm.remark"
            :placeholder="`请输入描述`"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogAddVisible"
      :append-to-body="true"
      width="90%"
    >
      <el-button size="small" type="primary" @click="clickRes" style="margin-bottom:10px">选择资源</el-button>
      <!-- <el-select v-model="ofrResourceIds" placeholder="请选择资源" filterable style="width: 100%" multiple size="small" @change="changeRes">
        <el-option-group
          v-for="(group, index) in resTreeList"
          :key="index"
          :label="group.name">
          <el-option
            v-for="item in group.resourceList"
            :key="item.ofrResourceId"
            :label="item.remarks"
            :value="item.ofrResourceId">
          </el-option>
        </el-option-group>
      </el-select> -->
      <el-table
        style="width: 100%"
        :data="bomData"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        border
        :summary-method="getSummaries"
        show-summary>
        <!-- 资源 -->
        <el-table-column
          prop="ofrResourceName"
          label="资源">
          <!-- <template slot-scope="scope">
            <a class="link">
              {{scope.row.ofrResourceName}}
            </a>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="pName"
          label="父级">
          <template slot-scope="scope">
            <a class="link" @click="clickP(scope.row.pid, scope.$index)">
              {{scope.row.pName}}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          align="right"
          header-align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.quantity"
              size="mini"
              clearable
              class="right-input"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="金额"
          align="right"
          header-align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.price"
              size="mini"
              clearable
              class="right-input"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="lossRate"
          label="损耗率">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.lossRate"
              size="mini"
              clearable
              class="right-input"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="描述">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remark"
              size="mini"
              clearable
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="jc"
          label="简称">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.jc"
              size="mini"
              clearable
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sort"
              size="mini"
              clearable
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="60"
          fixed="right"
          align="right"
          >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDelete(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm1" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="复制BOM"
      :visible.sync="dialogCopyVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="formCopy"
        :rules="rules"
        ref="formCopy"
        class="demo-ruleForm"
        style="max-height: 50vh; overflow-y: auto;overflow-x:hidden"
        label-width="60px"
      >
        <el-form-item label="资源" prop="ofrResourceId">
          <el-select v-model="formCopy.ofrResourceId" placeholder="请选择资源" filterable style="width: 100%">
            <el-option-group
              v-for="(group, index) in copyFormResources"
              :key="index"
              :label="group.name">
              <el-option
                v-for="item in group.resourceList"
                :key="item.ofrResourceId"
                :label="item.remarks"
                :value="item.ofrResourceId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCopyVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCopyForm('formCopy')" :loading="isLoading">确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="`导入BOM`"
      :visible.sync="dialogImportVisible"
      width="30%"
      append-to-body
      top="59px"
      class="res-dialog"
      >
      <el-upload
        class="upload-demo"
        :auto-upload="false"
        drag
        action="#"
        multiple
        accept=".xlsx, .xls"
        :show-file-list="false"
        :on-change="handleChange">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" @click="exportBtn">下载模板</el-link>
        </div>
      </el-upload>
      <!-- <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress>
      <el-checkbox v-model="done" style="margin-top:10px">是否正式</el-checkbox> -->
      <!-- <span style="margin-top:10px;color:red;display:inline-block">注：黄色字体表示选中多条资源</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogImportVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择资源"
      :visible.sync="dialogResVisible"
      :append-to-body="true"
      width="500px"
    >
      <el-select v-model="ofrResourceIds" placeholder="请选择资源" filterable style="width: 100%" multiple size="small">
        <el-option-group
          v-for="(group, index) in resTreeList"
          :key="index"
          :label="group.name">
          <el-option
            v-for="item in group.resourceList"
            :key="item.ofrResourceId"
            :label="item.remarks"
            :value="item.ofrResourceId">
          </el-option>
        </el-option-group>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogResVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRes">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="选择父级"
      :visible.sync="dialogPVisible"
      :append-to-body="true"
      width="500px"
    >
      <treeselect v-model="pid1" :options="options" :normalizer="normalizer" placeholder="请选择父级" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitP">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
import {mapState} from 'vuex'
import DictTag from '@/components/DictTag.vue'
import DimeTag from '@/components/DimeTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    props: {
      contentData: {
        type: Object,
        default: {}
      }
    },
    components: {
      Treeselect
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    data() {
      return {
        dialogFormVisible: false,
        ruleForm: {},
        formCopy: {},
        rules: {
          ofrResourceId: [{ required: true, message: '请选择资源', trigger: "change" }],
          ofrResourceIds: [{ required: true, message: '请选择资源', trigger: "change" }]
        },
        // 弹出层标题
        title: '',
        loading: true,
        tableData: [],
        isLoading: false,
        resTreeList: [],
        // 树选项
        options: [],
        dialogCopyVisible: false,
        copyFormResources: [],
        dialogImportVisible: false,
        dialogAddVisible: false,
        bomData: [],
        ofrResourceIds: [],
        pid: null,
        dialogResVisible: false,
        pName: null,
        pid1: null,
        dialogPVisible: false,
        index: null,
        ids: []
      }
    },
    mounted() {
      this.getList()
      this.getResTypeResource()
    },
    methods: {
      //指定列求和
      getSummaries(param) {
        // console.log(param);
        const { columns, data } = param;
        const sums = [];

        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "合计";
            return;
          }
          const values = data.map((item) => Number(item[column.property]));
          // 只合计amount1和amount3，想合并哪些列就在这里指定
          if (column.property === "quantity"||column.property === "price") {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          }
        });
        return sums;
      },
      // 批量编辑
      handleEditBatch() {
        this.$confirm('此操作将批量编辑BOM, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = JSON.parse(JSON.stringify(this.tableData))
          const flattenedArray = this.getOneArr(data).map(item => {
            return {
              bomId: item.bomId,
              quantity: item.quantity,
              price: item.price,
              lossRate: item.lossRate,
              remark: item.remark,
              jc: item.jc,
              sort: item.sort,
            }
          });
          // console.log(JSON.parse(JSON.stringify(flattenedArray)));
          this.isLoading = true
          this.$http.post(url.editBomBatch, flattenedArray).then(res => {
            // console.log(res);
            this.isLoading = false
            if(res.data.code === 200) {
              this.$message.success(res.data.msg)
              this.getList()
            }
          })
        }).catch(() => {
        });

      },
      // 批量删除
      handleDelBatch() {
        this.$confirm('此操作将批量删除BOM, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let bomIds = this.ids.toString()
          this.$http.post(url.removeBomBatch + bomIds).then(res => {
            if(res.data.code === 200) {
              this.$message.success(res.data.msg)
              this.getList()
            }
          })
        }).catch(() => {
        });

      },
      // 减行
      handleDelete(row, index) {
        this.bomData.splice(index, 1)
      },
      clickP(pid, index) {
        this.dialogPVisible = true
        this.pid1 = pid
        this.index = index
      },
      submitP() {
        this.bomData[this.index].pid = this.pid1
        this.bomData[this.index].pName = this.getPName(this.pid1)
        this.dialogPVisible = false
      },
      clickRes() {
        this.dialogResVisible = true
        this.ofrResourceIds = []
      },
      submitRes() {
        this.dialogResVisible = false
        this.ofrResourceIds.forEach(item => {
          this.bomData.push({
            pid: this.pid,
            pName: this.pName,
            rid: this.contentData.ofrResourceId,
            ofrResourceId: item,
            quantity: null,
            price: this.getOfrResource(item, 'rprice'),
            remark: null,
            lossRate: null,
            ofrResourceName: this.getOfrResource(item, 'remarks')
          })
        })
      },
      getOfrResource(ofrResourceId, key) {
        let obj = {}
        this.resTreeList.forEach(item => {
          if(item.resourceList){
            item.resourceList.forEach(val => {
              if(val.ofrResourceId == ofrResourceId) {
                obj = val
              }
            })
          }

        })
        return obj[key] ? obj[key] : null
      },
      // bom导入
      handleImport() {
        // this.loadProgress = 0
        // this.progressFlag = false
        this.dialogImportVisible = true
      },
      // 下载模板
      exportBtn() {
        // this.$http.get(url.importTemplate + this.ofoFlowWork.ofoFlowWorkId).then(res => {
        //   console.log(res);
        //   console.log(this.$base_url);
        //   if(res.data.code == 200) {
        //     // window.open(this.$base_url + res.data.msg, '_self')
        //   }

        // })
        const loading = this.$loading({
          lock: true,
          text: '下载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        this.$http({
          url: url.importBomTemplate,
          method: 'post',
          responseType: 'blob',
          headers: {            //注意
            'Content-Type': 'application/json; application/octet-stream'
          }
        }).then(res => {
          // console.log(res);
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', `BOM导入模板${new Date().getTime()}.xls`);
          document.body.appendChild(link);
          link.click();
          loading.close();
        })
      },
      handleChange(file) {
        const loading = this.$loading({
          lock: true,
          text: '导入中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        // this.progressFlag = true
        const formData = new FormData()
        formData.append('file', file.raw)
        formData.append('rid', this.contentData.ofrResourceId)
        // let time = new Date().getTime() + Math.random()
        // formData.append('key', time)
        // this.getProgress(time)
        this.$http.post(url.importBomData + this.contentData.ofrResourceId,
          formData
        ).then(res => {
          loading.close();
          if(res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.dialogImportVisible = false
            this.getList()
          }
        })
      },
      judgeButton(type) {
        return this.currentMenu.perms.some(item => item.menuType == 'F' && item.perms == `${this.currentMenu.kind}:${type}`)
      },
      getResTypeResource() {
        this.$http.get(url.resTypeResource + '?kind=' + this.contentData.kind).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.resTreeList = res.data.data
          }
        })
      },
      // 拿资源维度列表
      getList() {
        this.loading = true
        this.options = []
        this.$http.get(url.bomResource + this.contentData.ofrResourceId).then(res => {
          this.tableData = this.$handleTree(res.data.data, 'bomId', 'pid')
          const data = {bomId: 0, name: '顶级节点', children: []};
          data.children = this.tableData;
          this.options.push(data)
          this.loading = false
          // console.log(JSON.parse(JSON.stringify(this.tableData)));
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
          if (node.children && !node.children.length) {
            delete node.children;
          }
          return {
            id: node.bomId,
            label: node.name,
            children: node.children
          };
        // })
      },
      // inquire() {
      //   this.pageNum = 1
      //   this.getList()
      // },
      reset() {
        this.ruleForm = {
          ofrResourceId: null,
          pid: 0,
          price: null,
          quantity: null,
          remark: null,
          rid: this.contentData.ofrResourceId,
          lossRate: null
        }
      },
      // 表格选中框事件
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.bomId)
        // this.edit = rows.length === 1
        // this.del = rows.length
      },
      // handleView(row) {
      //   // console.log(row);
      //   this.viewTitle = row.name
      //   this.dialogViewVisible = true
      //   this.$http.get(url.getOfrThing, {params: {ofrThingId: row.ofrThingId}}).then(res => {
      //     if(res.data.code === 200) {
      //       // console.log(res.data);
      //       this.ruleForm = res.data.data.ofrThing
      //       this.formList = res.data.data.dimensionList
      //       this.ruleForm.dataSourceConfig = this.formList
      //     }
      //   })
      // },
      // 表格的修改按钮
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.bomId || this.ids[0]
        this.title = `修改BOM`
        this.dialogFormVisible = true
        this.$http.get(url.bomDetail + id).then(res => {
          if(res.data.code === 200) {
            this.ruleForm = res.data.data
            this.ruleForm.ofrResourceIds = []
          }
        })
      },
      // 表格的删除按钮
      handleDel(row) {
        // this.selected = row
        const ids = row.bomId || this.ids.toString()
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeBomResource + ids).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })

        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // console.log(this.ruleForm);
            // let dimes = []
            // if(this.formList.length != 0) {
            //   dimes = this.handleDimes(this.formList)
            // }
            let query = JSON.parse(JSON.stringify(this.ruleForm))
            query.ofrResourceIds = query.ofrResourceIds.toString()
            if(!this.ruleForm.bomId) {
            //   // console.log(1111);
              // this.$http.post(url.addBomBatch, query).then(res => {
              //   // console.log(res);
              //   this.isLoading = false
              //   if(res.data.code === 200) {
              //     this.$message.success(res.data.msg)
              //     this.dialogFormVisible = false
              //     this.getList()
              //   }
              // })
            }else {
              // console.log(22222222222);
              this.$http.post(url.editBomResource, query).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      submitForm1() {
        this.isLoading = true
        this.$http.post(url.addBomBatch, this.bomData).then(res => {
          // console.log(res);
          this.isLoading = false
          if(res.data.code === 200) {
            this.$message.success(res.data.msg)
            this.dialogAddVisible = false
            this.getList()
          }
        })
      },
      submitCopyForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            this.$http.post(url.copyBom, this.formCopy).then(res => {
              // console.log(res);
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.dialogCopyVisible = false
                this.getList()
              }
            })
          } else {
            return false;
          }
        });
      },
      handleAdd(row) {
        this.title = `新增Bom`
        // this.$nextTick(() => {
        //   // 清除表单校验效果\
        //   this.$refs.ruleForm.clearValidate();
        // })
        this.pid = row.bomId
        this.pName = this.getPName(row.bomId)
        this.bomData = []
        this.dialogAddVisible = true
        // this.reset()
        // if(row) {
        //   this.ruleForm.pid = row.bomId
        // }
      },
      getPName(bomId) {
        let data = JSON.parse(JSON.stringify(this.options))
        const flattenedArray = this.getOneArr(data);
        let name = ''
        flattenedArray.forEach(item => {
          if(item.bomId == bomId) {
            name = item.name
          }
        })
        return name
      },
      getOneArr (data) {
        let newData = []
        const callback = (item) => {
          (item.children || (item.children = [])).map(v => {
            callback(v)
          })
          delete item.children
          newData.push(item)
        }
        data.map(v => callback(v))
        return newData.reverse()
      },
      handleCopy() {
        this.$nextTick(() => {
        // console.log(this.$refs);
          // 清除表单校验效果\
          if(this.$refs.formCopy) {
            this.$refs.formCopy.clearValidate();
          }
        })
        this.dialogCopyVisible = true
        this.formCopy = {
          ofrResourceId: "",
          rid: this.contentData.ofrResourceId
        }
        this.$http.get(url.copyBomResources).then(res => {
          // console.log(res);
          if(res.data.code === 200) {
            this.copyFormResources = res.data.data
          }
        })
      },
      // handleSizeChange(val) {
      //   this.pageSize = val
      //   this.pageNum = 1
      //   this.getList()
      // },
      // handleCurrentChange(val) {
      //   this.pageNum = val
      //   this.getList()
      // },
    },
    watch: {
      contentData(newValue, oldValue) {
        // console.log(newValue);
        this.getList()
      }
    },
  }
</script>

<style lang="scss" scoped>
.tab1 {
  .el-table {
    margin-bottom: 39px;
  }
}
.right-input {
  ::v-deep .el-input__inner {
    text-align: right;
  }
}
::v-deep .el-table__footer-wrapper tbody td.el-table__cell, .el-table__header-wrapper tbody td.el-table__cell {
  /* text-align: right; */
  padding-right: 15px
}
/* ::v-deep .el-dialog__body {
  padding: 30px 0 30px 20px;
} */
</style>
