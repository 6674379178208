import Report from '@/views/report/Index.vue'

import Inventory from '@/views/report/inventory/Index.vue'
import Person from '@/views/report/person/Index.vue'
import Resource from '@/views/report/resource/Index.vue'
import Content from '@/views/report/content/Index.vue'
import Org from '@/views/report/org/index.vue'
import InReport from '@/views/report/inReport/Index.vue'
import Inquire from '@/views/report/inquire/Index.vue'
import owhThing from '@/views/report/owhThing/Index.vue'

import Develop from '@/views/develop/Index.vue'

const routes = [
  {
    path: '/report',
    name: 'report',
    redirect: '/report/resource',
    meta: {
      keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    },
    component: Report,
    children: [
      {
        path: 'inventory',
        name: 'inventory',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Inventory
      },
      {
        path: 'person',
        name: 'person',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Person
      },
      {
        path: 'resource',
        name: 'resource',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Resource
      },
      {
        path: 'content',
        name: 'content',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Content
      },
      {
        path: 'org',
        name: 'org',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Org
      },
      {
        path: 'inReport',
        name: 'inReport',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: InReport
      },
      {
        path: 'inquire',
        name: 'inquire',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Inquire
      },,
      {
        path: 'owhThing',
        name: 'owhThing',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: owhThing
      },
      {
        path: '*',
        name: 'develop',
        component: Develop
      },
    ]
  }
]

export default routes