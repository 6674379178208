<template>
  <div class="resource">
    <div class="sift">
      <div class="title">库存查询</div>
      <div class="inquire">
        <el-form :model="formInline" inline label-width="40px" size="small">
          <el-form-item>
            <strong slot="label">位置</strong>
            <treeselect style="width:240px" v-model="formInline.cbowhId" :options="cbOwhTree" :normalizer="normalizer" placeholder="请选择位置" />
          </el-form-item>
          <el-form-item>
            <strong slot="label">资源</strong>
            <treeselect style="width:240px" v-model="formInline.rid" :options="resourceTree" :normalizer="normalizer1" placeholder="请选择资源" @select="handleResChange" clearable @clear="handleClear" />
          </el-form-item>
          <el-form-item>
            <strong slot="label">时间</strong>
            <el-date-picker
              style="width:240px"
              v-model="formInline.month"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              format="yyyy-MM"
              value-format="yyyy-MM"
              :clearable="false">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button> -->
      </div>
    </div>
    <div class="detail">
      <!-- <div style="display:flex;align-items:center;margin-bottom: 16px"> -->
        <div class="title">
          库存报表
        </div>
        <!-- <el-button type="warning" size="mini" @click="exportToExcel" style="margin-left:10px">导出</el-button> -->
      <!-- </div> -->
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        :max-height="tableHeight"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column prop="ofrresourceName" label="产品名称" width="180" fixed="left">
          <!-- <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.ofrresourceName}}</a>
          </template> -->
        </el-table-column>
        <el-table-column prop="cbowhName" label="位置" fixed="left" width="100"></el-table-column>
        <el-table-column label="期初" header-align="center">
          <el-table-column prop="qcQuantity" label="数量" align="right" header-align="center">
            <template slot-scope="scope">
              {{scope.row.qcQuantity ? scope.row.qcQuantity : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="qcCost" label="成本" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.qcCost | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="qcTotal" label="金额" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.qcTotal | comdify('no')}}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="入库" header-align="center">
          <el-table-column prop="bqRkQuantity" label="数量" align="right" header-align="center">
            <template slot-scope="scope">
              {{scope.row.bqRkQuantity ? scope.row.bqRkQuantity : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="bqRkCost" label="成本" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.bqRkCost | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="bqRkTotal" label="金额" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.bqRkTotal | comdify('no')}}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="出库" header-align="center">
          <el-table-column prop="bqCkQuantity" label="数量" align="right" header-align="center">
            <template slot-scope="scope">
              {{scope.row.bqCkQuantity ? scope.row.bqCkQuantity : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="bqCkCost" label="成本" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.bqCkCost | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="bqCkTotal" label="金额" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.bqCkTotal | comdify('no')}}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="期末" header-align="center">
          <el-table-column prop="quantity" label="数量" align="right" header-align="center">
            <template slot-scope="scope">
              {{scope.row.quantity ? scope.row.quantity : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="cost" label="成本" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.cost | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="total" label="金额" align="right" header-align="center" width="120">
            <template slot-scope="scope">
              {{scope.row.total | comdify('no')}}
            </template>
          </el-table-column>
        </el-table-column>
        
      </el-table>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-drawer
      title="明细"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="90%">
    <!-- <el-dialog
      title="明细"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="80%"
      top="59px"
    > -->
      <el-table
        :data="tableData1"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading1"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        height="calc(100% - 64px)"
      >
        <el-table-column prop="ofoworkName" label="活动">
          <template slot-scope="scope">
            <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofrresourceName" label="资源" min-width="120"></el-table-column>
        <el-table-column prop="cbowhName" label="仓库" min-width="80"></el-table-column>
        <el-table-column prop="stockaccttype" label="类型">
          <template slot-scope="scope">
            <DictTag :options="dropDown.stockacctType" :value="scope.row.stockaccttype"/>
          </template>
        </el-table-column>
        <el-table-column prop="workQuantity" label="单据数量" align="right" header-align="center"></el-table-column>
        <el-table-column prop="workTotal" label="单据总价" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.workTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="剩余数量" align="right" header-align="center">
          <!-- <template slot-scope="scope"> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column prop="cost" label="平均成本" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.cost | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="linetotal" label="金额" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.linetotal | comdify}}
          </template>
        </el-table-column>
        
        <el-table-column prop="createTime" label="时间" align="center" width="160">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="pageNum1"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize1"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total1"
        style="margin-top:16px">
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-drawer>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    DictTag,
    Treeselect
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        cbowhId: null,
        rid: null,
        month: [
          this.parseTime(Date.now(), '{y}-{m}'),
          this.parseTime(Date.now(), '{y}-{m}')
        ],
        type: ''
      },
      tableData: [],
      dialogFormVisible: false,
      // 弹出层标题
      title: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      pageNum1: 1,
      pageSize1: 10,
      total1: 0,
      loading1: true,
      tableData1: [],
      cbOwhTree: [],
      defaultProps: {
        /** 唯一标识 **/
        value: 'Id',
        /** 标签显示 **/
        label: 'label',
        /** 子级 **/
        children: 'children' },
      cbowhId: '',
      ofrresourceId: '',
      loginUser: JSON.parse(sessionStorage.getItem('loginUser')),
      resourceTree: [],
      tableHeight: window.innerHeight - 215,
    };
  },
  created() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 215;
    })
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.tableHeight = window.innerHeight - 215;
      })()
    }
    this.getList();
    this.init()
  },
  methods: {
    // 表格数据写入excel，并导出为Excel文件
    exportToExcel(){
      // const XLSX = require('xlsx')
      // console.log('XLSX',XLSX,FileSaver)
      // // 使用 this.$nextTick 是在dom元素都渲染完成之后再执行
      // this.$nextTick(function () {
      // // 设置导出的内容是否只做解析，不进行格式转换     false：要解析， true:不解析
      // const xlsxParam = { raw: true } 
      // const wb = XLSX.utils.table_to_book(document.querySelector('#oIncomTable'), xlsxParam)
      // // 导出excel文件名
      // let fileName = '营业收入' + new Date().getTime() + '.xlsx'
      
      // const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
      // try {
      //   // 下载保存文件
      //   FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName)
      // } catch (e) {
      //   if (typeof console !== 'undefined') {
      //   console.log(e, wbout)
      //   }
      // }
      // return wbout
      // })
    },
    handleResChange(node) {
      // console.log(node);
      this.formInline.type = node.type
      this.formInline.rid = node.id
      this.getList()
    },
    handleClear() {
      console.log(111111);
    },
    init() {
      // 获取位置树
      this.$http.get(url.getCbOwhTree, {params: {
        kind: '0'
      }}).then(res => {
        if(res.data.code == 200) {
          this.cbOwhTree = this.getTree(res.data.data, 'name', 'cbOwhId')
        }
      })
      // 获取资源树
      this.$http.get(url.resourceTree).then(res => {
        if(res.data.code == 200) {
          // this.resourceTree = res.data.data
          this.resourceTree = this.getTree1(res.data.data, 'name', 'id')
        }
      })
    },
    /*转化tree标准格式*/
    getTree(tree, label, id) {
      // console.log(tree);
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item[label];
          obj.id = item[id];
          if (item.children && item.children.length) {
            obj.children = this.getTree(item.children, label, id);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    
    /*转化tree标准格式*/
    getTree1(tree, label, id) {
      // console.log(tree);
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item[label];
          obj.id = item[id];
          obj.type = item.type
          if (item.children && item.children.length) {
            obj.children = this.getTree1(item.children, label, id);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    /** 转换数据结构 */
    normalizer(node) {
      // console.log(node);
      this.$nextTick(() => {
        if (node.children && !node.children.length) {
          delete node.children;
          
          return {
            id: node.cbOwhId,
            label: node.name,
            children: node.children
          };
        }
      })
    },
    /** 转换数据结构 */
    normalizer1(node) {
      // console.log(node);
      this.$nextTick(() => {
        if (node.children && !node.children.length) {
          delete node.children;
          
          return {
            id: node.id,
            label: node.name,
            children: node.children
          };
        }
      })
    },
    // 查询按钮
    inquire() {
      this.pageNum = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.formInline.cbowhId = null
      this.pageNum = 1
      this.getList()
    },
    // 删除按钮
    handleDel(row) {
      this.$confirm('此操作将永久删除该资源类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeOwhResource, qs.stringify({cbowhId: row.cbowhId, ofrresourceId: row.ofrresourceId})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageNum = 1
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 获取字典数据
    getList() {
      this.loading = true
      // 获取列表数据
      this.$http.get(url.owhResourceReport, {params:{
        cbowhId: this.formInline.cbowhId,
        rid: this.formInline.rid,
        begin: this.formInline.month[0],
        end: this.formInline.month[1],
        type: this.formInline.type
      }}).then(res => {
        this.loading = false
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data
        }
      })
    },
    
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    handleSizeChange1(val) {
      this.pageSize1 = val
      this.pageNum1 = 1
      this.getList1()
    },
    handleCurrentChange1(val) {
      this.pageNum1 = val
      this.getList1()
    },
    getList1() {
      this.loading1 = true
      this.$http.get(url.getOwhResourceListById, {params: {
        cbowhId: this.cbowhId,
        ofrresourceId: this.ofrresourceId,
        pageNum: this.pageNum1,
        pageSize: this.pageSize1,
      }}).then(res => {
        this.loading1 = false
        if(res.data.code == 200) {
          this.tableData1 = res.data.data.list
          this.total1 = res.data.data.total
        }
      })
    },
    // 查看详情按钮
    handleDetail(row) {
      // 获取库存类型下拉
      if(!this.dropDown.stockacctType){
        this.$http.get(url.getDataList, {params: {type: 'buss_stockacct_type'}}).then(res => {
          this.$store.commit('setStockacctType', res.data.data)
        })
      }
      this.dialogFormVisible = true
      this.cbowhId = row.cbowhId
      this.ofrresourceId = row.ofrresourceId
      this.pageNum1 = 1
      this.getList1()
    },
    goProject(row) {
      this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: row.ofoworkId}}).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.$router.push({
            path: '/project/' + res.data.data.ofoItemId,
          });
          this.$store.commit("setJumpData", {...res.data.data, turn: true})
          let obj = {
            path: "/project/" + res.data.data.ofoItemId,
            name: "proDetail",
            label: "项目详情",
            ofoItemId: res.data.data.ofoItemId,
            children: []
          };
          this.$store.commit('setSecondMenu', [])
          this.$store.commit("selectMenu", obj);
        }
      })
    }
  },
  watch: {
    formInline: {
      handler(newValue, oldValue) { 
        // console.log(newValue);
        if(!newValue.rid) {
          this.formInline.type = ''
          this.getList()
        }
      },
      deep: true
    }
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">

</style>