import url from '@/config/url'
// 处理表单数据
export default function handleFormList(list) {
  // console.log(list);
  if(list) {
    list.forEach(item => {
      if(item.editType == '7') {
        if(item.dimeValue) {
          item.dimeValue = JSON.parse(item.dimeValue).map(Number)
        }else {
          item.dimeValue = []
        }
      }else if(item.editType == '8') {
        if(item.dimeValue) {
          item.dimeValue = JSON.parse(item.dimeValue)
        }else {
          item.dimeValue = []
        }
      }else if(item.editType == '12') { // 系统资源
        if(item.dimeValue) {
          if(item.dimeValue.indexOf('[') == 0 && (item.dimeValue.indexOf(']') == (item.dimeValue.length -1))) {
            item.dimeValue = JSON.parse(item.dimeValue).map(Number)
          }else {
            item.dimeValue = [Number(item.dimeValue)]
          }
        }else {
          item.dimeValue = []
        }
        this.$http.get(url.getResTypeAndList, {params: {
          cbRestypeId: item.resourceType
        }}).then(res => {
          item.options = res.data.data.list
        })
      }else if(item.editType == '13') { // 系统内容
        if(item.dimeValue) {
          if(item.dimeValue.indexOf('[') == 0 && (item.dimeValue.indexOf(']') == (item.dimeValue.length -1))) {
            item.dimeValue = JSON.parse(item.dimeValue).map(Number)
          }else {
            item.dimeValue = [Number(item.dimeValue)]
          }
        }else {
          item.dimeValue = []
        }
        this.$http.get(url.getResTypeAndList, {params: {
          cbRestypeId: item.resourceType
        }}).then(res => {
          item.options = handleEmptyTree(res.data.data.list, 'ofrThingList')
        })
      }else if(item.editType == '14') { // 系统项目
        if(item.dimeValue) {
          if(item.dimeValue.indexOf('[') == 0 && (item.dimeValue.indexOf(']') == (item.dimeValue.length -1))) {
            item.dimeValue = JSON.parse(item.dimeValue).map(Number)
          }else {
            item.dimeValue = [Number(item.dimeValue)]
          }
        }else {
          item.dimeValue = []
        }
        this.$http.get(url.getAllItemList).then(res => {
          item.options = handleEmptyTree(res.data.data, 'ofoFlowItemList')
        })
      }else if(item.editType == '20') { // 下拉树
        if(item.dimeValue) {
          // if(item.dimeValue.indexOf('[') == 0 && (item.dimeValue.indexOf(']') == (item.dimeValue.length -1))) {
          //   item.dimeValue = JSON.parse(item.dimeValue).map(Number)
          // }else {
          //   item.dimeValue = [Number(item.dimeValue)]
          // }
        }else {
          item.dimeValue = null
        }
        item.options = getTree(JSON.parse(item.options))
      }else {
        // if(item.dimeValue) {
        //   item.dimeValue = item.dimeValue
        // }else {
        //   item.dimeValue = ''
        // }
      }
    })
  }
  return list
}

function handleEmptyTree(list, firstKey) {
  let arr = []
  list.forEach(item => {
    if(item[firstKey]) {
      arr.push(item)
    }
  })
  return arr
}

/*转化tree标准格式*/
function getTree(tree) {
  let arr = [];
  if (tree.length !== 0) {
    tree.forEach(item => {
      let obj = {};
      obj.label = item.optionLabel;
      obj.id = item.optionValue;
      if (item.children && item.children.length > 0) {
        obj.children = getTree(item.children);
      }
      arr.push(obj);
    });
  }
  return arr
}