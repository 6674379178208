<template>
  <div class="resource">
    <div class="sift">
      <div class="title">库存查询</div>
      <div class="inquire">
        <el-form :model="formInline" class="demo-form-inline" label-width="40px" inline size="small">
          <el-form-item>
            <strong slot="label">位置</strong>
            <treeselect v-model="formInline.cbowhId" :options="cbOwhTree" :normalizer="normalizer" placeholder="请选择位置" style="width:240px" />
          </el-form-item>
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input v-model="formInline.ofrresourceName" placeholder="请输入产品名称" clearable  @keyup.enter.native="inquire" @clear="inquire" style="width:240px"  />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button> -->
      </div>
    </div>
    <div class="detail">
      <div class="title">库存列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        :max-height="tableHeight"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <!-- <el-table-column prop="ofOwhResourceId" label="序号"></el-table-column> -->
        <el-table-column prop="cbowhName" label="位置">
          
        </el-table-column>
        <el-table-column prop="ofrresourceName" label="产品名称">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.ofrresourceName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="数量" align="right">
          <!-- <template slot-scope="scope"> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column prop="cost" label="成本单价" align="right">
          <template slot-scope="scope">
            {{scope.row.cost | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="linetotal" label="成本合计" align="right">
          <template slot-scope="scope">
            {{scope.row.linetotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column 
          label="操作"
          fixed="right"
          width="70"
          v-if="loginUser.isAdmin == '0'">
          <template slot-scope="scope">
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-drawer
      title="明细"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="90%">
    <!-- <el-dialog
      title="明细"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="80%"
      top="59px"
    > -->
      <el-table
        :data="tableData1"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading1"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        height="calc(100% - 64px)"
      >
        <el-table-column prop="ofoworkName" label="活动">
          <template slot-scope="scope">
            <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofrresourceName" label="资源" min-width="120"></el-table-column>
        <el-table-column prop="cbowhName" label="仓库" min-width="80"></el-table-column>
        <el-table-column prop="stockaccttype" label="类型">
          <template slot-scope="scope">
            <DictTag :options="dropDown.stockacctType" :value="scope.row.stockaccttype"/>
          </template>
        </el-table-column>
        <el-table-column prop="workQuantity" label="单据数量" align="right" header-align="center"></el-table-column>
        <el-table-column prop="workTotal" label="单据总价" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.workTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="剩余数量" align="right" header-align="center">
          <!-- <template slot-scope="scope"> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column prop="cost" label="平均成本" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.cost | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="linetotal" label="金额" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.linetotal | comdify}}
          </template>
        </el-table-column>
        
        <el-table-column prop="createTime" label="时间" align="center" width="160">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="pageNum1"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize1"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total1"
        style="margin-top:16px">
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-drawer>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    DictTag,
    Treeselect
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        ofrresourceName: "",
        cbowhId: null,
      },
      tableData: [],
      dialogFormVisible: false,
      // 弹出层标题
      title: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      pageNum1: 1,
      pageSize1: 10,
      total1: 0,
      loading1: true,
      tableData1: [],
      cbOwhTree: [],
      defaultProps: {
        /** 唯一标识 **/
        value: 'Id',
        /** 标签显示 **/
        label: 'label',
        /** 子级 **/
        children: 'children' },
      cbowhId: '',
      ofrresourceId: '',
      loginUser: JSON.parse(sessionStorage.getItem('loginUser')),
      tableHeight: window.innerHeight - 245
    };
  },
  created() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 245;
    })
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.tableHeight = window.innerHeight - 245;
      })()
    }
    this.getList();
    this.init()
  },
  methods: {
    init() {
      this.$http.get(url.getCbOwhTree, {params: {
        kind: '0'
      }}).then(res => {
        if(res.data.code == 200) {
          this.cbOwhTree = this.getTree(res.data.data)
        }
      })
    },
    /*转化tree标准格式*/
    getTree(tree) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.name;
          obj.id = item.cbOwhId;
          if (item.children.length > 0) {
            obj.children = this.getTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    
    /** 转换数据结构 */
    normalizer(node) {
      // console.log(node);
      this.$nextTick(() => {
        if (node.children && !node.children.length) {
        delete node.children;
        
        return {
          id: node.cbOwhId,
          label: node.name,
          children: node.children
        };
      }
      })
    },
    // 查询按钮
    inquire() {
      this.pageNum = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.formInline.cbowhId = null
      this.pageNum = 1
      this.getList()
    },
    // 删除按钮
    handleDel(row) {
      console.log(row);
      this.$confirm('此操作将永久删除该资源类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeOwhResource, qs.stringify({cbowhId: row.cbowhId, ofrresourceId: row.ofrresourceId})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageNum = 1
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 获取字典数据
    getList() {
      this.loading = true
      // 获取列表数据
      this.$http.get(url.getOwhResourceList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          cbowhId: this.formInline.cbowhId == '0' ? '' : this.formInline.cbowhId,
          ofrresourceName: this.formInline.ofrresourceName
        }
      }).then(res => {
        this.loading = false
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      })
    },
    
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    handleSizeChange1(val) {
      this.pageSize1 = val
      this.pageNum1 = 1
      this.getList1()
    },
    handleCurrentChange1(val) {
      this.pageNum1 = val
      this.getList1()
    },
    getList1() {
      this.loading1 = true
      this.$http.get(url.getOwhResourceListById, {params: {
        cbowhId: this.cbowhId,
        ofrresourceId: this.ofrresourceId,
        pageNum: this.pageNum1,
        pageSize: this.pageSize1,
      }}).then(res => {
        this.loading1 = false
        if(res.data.code == 200) {
          this.tableData1 = res.data.data.list
          this.total1 = res.data.data.total
        }
      })
    },
    // 查看详情按钮
    handleDetail(row) {
      // 获取库存类型下拉
      if(!this.dropDown.stockacctType){
        this.$http.get(url.getDataList, {params: {type: 'buss_stockacct_type'}}).then(res => {
          this.$store.commit('setStockacctType', res.data.data)
        })
      }
      this.dialogFormVisible = true
      this.cbowhId = row.cbowhId
      this.ofrresourceId = row.ofrresourceId
      this.pageNum1 = 1
      this.getList1()
    },
    goProject(row) {
      this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: row.ofoworkId}}).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.dialogFormVisible = false
          this.$router.push({
            path: '/project/' + res.data.data.ofoItemId,
          });
          this.$store.commit("setJumpData", {...res.data.data, turn: true})
          let obj = {
            path: "/project/" + res.data.data.ofoItemId,
            name: "proDetail",
            label: "项目详情",
            ofoItemId: res.data.data.ofoItemId,
            children: []
          };
          this.$store.commit('setSecondMenu', [])
          this.$store.commit("selectMenu", obj);
        }
      })
    }
  },
  watch: {
    formInline: {
      handler(newValue, oldValue) { 
        // console.log(newValue);
        if(newValue.cbowhId != '0') {
          // console.log(11111);
          this.pageNum = 1
          this.loading = true
          this.getList()
        }
      },
      deep: true
    }
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">

</style>