<template>
  <div class="resource">
    <div class="sift">
      <div class="title">资源查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">类型</strong>
            <el-select v-model="formInline.cbRestypeId" placeholder="请选择类型" filterable @change="changeRestypeId" >
              <el-option-group
                v-for="group in resource"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.resourceName"
              placeholder="请输入名称"
              clearable
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button> -->
      </div>
    </div>
    <div class="detail">
      <div class="title">资源列表</div>
      <el-table ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        :max-height="tableHeight"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column prop="resourceName" label="资源名称" min-width="180" fixed="left">
        <template slot-scope="scope">
          <a @click="handleDetail(scope.row)" class="link">{{scope.row.resourceName}}</a>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, index) in cbussDimeList" :key="index" :prop="item.dimeName" :label="item.dimeName">
        <template slot-scope="scope">
          <div v-for="(val, id) in scope.row.obussDimeList" :key="id">
            <div v-if="item.cbdId == val.cbdId">{{val.dimeText}}</div>
          </div>
        </template>
      </el-table-column>

      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-drawer
      :title="contentData.resourceName"
      :visible.sync="drawer"
      size="70%">
      <div style="padding:16px">
        <!-- <el-descriptions>
          <el-descriptions-item label="代码">{{contentData.resourceNumber}}</el-descriptions-item>
          <el-descriptions-item label="类型">{{resData.restypeName}}
          </el-descriptions-item>
          <el-descriptions-item label="状态">
            <DictTag :options="dropDown.documentstatus" :value="contentData.documentstatus"/>
          </el-descriptions-item>
        </el-descriptions> -->
        <!-- 内部 -->
        <template v-if="resData.kindDealType == '0' || resData.kindDealType == null">
          <el-tabs v-model="thingName" type="border-card" class="tabs1">
            <el-tab-pane label="内容" name="1">
              <InContent v-if="thingName == '1'" :resourceName="contentData.resourceName" :ofrResourceId="contentData.ofrResourceId" ref="inContent" :resData="resData"/>
            </el-tab-pane>
          </el-tabs>
        </template>
        <!-- 外部 -->
        <template v-if="resData.kindDealType == '1'">
          <el-tabs v-model="thingName" type="border-card" class="tabs1">
            <el-tab-pane label="联系信息" name="2">
              <OutContent v-if="thingName == '2'" :thingDimeType="'1'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="发票信息" name="3">
              <OutContent v-if="thingName == '3'" :thingDimeType="'2'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="账户信息" name="4">
              <OutContent v-if="thingName == '4'" :thingDimeType="'3'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="物流信息" name="5">
              <OutContent v-if="thingName == '5'" :thingDimeType="'4'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
            <el-tab-pane label="其他信息" name="6">
              <OutContent v-if="thingName == '6'" :thingDimeType="'9'" :resourceName="contentData.resourceName" :resId="contentData.ofrResourceId" :cbRestypeId="resData.cbRestypeId" :resData="resData"/>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import DictTag from '@/components/DictTag.vue'
import OutContent from '@/components/resource/kind/content/OutIndex.vue'
import InContent from '@/components/resource/kind/content/InIndex.vue'
export default {
  components: {
    DictTag,
    OutContent,
    InContent
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        cbRestypeId: "",
        resourceName: "",
        resourceNumber: ""
      },
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      resource: [],
      cbussDimeList: [],
      drawer: false,
      contentData: {},
      thingName: '',
      resData: {},
      tableHeight: window.innerHeight - 245
    };
  },
  created() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 245;
    })
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.tableHeight = window.innerHeight - 245;
      })()
    }
    // 获取资源类型列表
    this.$http.get(url.getResTypeTree).then(res => {
      if(res.data.code == 200) {
        this.resource = res.data.data
        this.formInline.cbRestypeId = res.data.data[0].children[0].id
        this.getResData()
        this.getList();
        this.getCbussDimeList()
      }
    })
  },
  methods: {
    // 请求数据
    getResData() {
      this.$http.get(url.getResType, {params: {cbRestypeId: this.formInline.cbRestypeId}}).then(res => {
        this.resData = res.data.data
      })
    },
    // 查询按钮
    inquire() {
      this.loading = true
      this.pageNum = 1
      this.getList()
      this.getCbussDimeList()
    },
    changeRestypeId() {
      this.getResData()
      this.inquire()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.formInline.cbRestypeId = res.data.data[0].children[0].id
      this.loading = true
      this.pageNum = 1
      this.getList()
      this.getCbussDimeList()
    },
    // 获取维度列表
    getCbussDimeList() {
      this.$http.get(url.getCbussDimeList, { params: {
        cbussId: this.formInline.cbRestypeId,
        dimetype: '1'
      } }).then((res) => {
        if(res.data.code == 200) {
          this.cbussDimeList = res.data.data
          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        }
      });
    },
    // 获取字典数据
    getList() {
      // 获取列表数据
      this.$http.get(url.getAllOfrResourceList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline
        }
      }).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
    // 查看详情按钮
    async handleDetail(row) {
      await this.$http.get(url.getOfrResource, {params: {ofrResourceId: row.ofrResourceId}}).then(res => {
        if(res.data.code == 200) {
          this.contentData = res.data.data.ofrResource
        }
      })
      this.drawer = true
      if(this.resData.kindDealType == '1') {
        this.thingName = '2'
      }else {
        this.thingName = '1'
        // console.log(this.$refs.inContent);
        if(this.$refs.inContent) {
          this.$refs.inContent.getList()
        }
        }
    },
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">
</style>
